import React from "react"
import styled from "styled-components"
import {sizes, colors} from "../../constants/css"

function Arrow({inverted, color = "black"}) {
  return (
    <Svg
      color={color}
      inverted={inverted}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 54 51.7"
      style={{enableBackground: "new 0 0 54 51.7"}}
    >
      <g>
        <g>
          <path d="M0,0v51.7l52.6-22.4c1.9-1.9,1.9-4.9,0-6.8L0,0z" />
        </g>
      </g>
    </Svg>
  )
}

const Svg = styled.svg`
  transform: ${({inverted}) => (inverted ? "scaleX(-1)" : "")};
  width: ${sizes.playerIcon}px;
  fill: ${({color}) => colors[color]};
`
export default Arrow
