// 3rd party
import React from "react"
import {useDispatch, useSelector} from "react-redux"
import styled from "styled-components"

// components
import CloseClickOutside from "../general/closeClickOutside"
import Hamburger from "./hamburger"
import Nav from "./nav"

// actions
import {setIsMenuOpen} from "../../actions/index"

function NavOpener() {
  const isMenuOpen = useSelector(state => state.isMenuOpen)
  const dispatch = useDispatch()
  return (
    <CloseClickOutside setIsOpen={() => dispatch(setIsMenuOpen(false))} isActive={isMenuOpen}>
      <HamburgerWrapper>
        <Hamburger>
          <Nav />
        </Hamburger>
      </HamburgerWrapper>
    </CloseClickOutside>
  )
}

const HamburgerWrapper = styled.div`
  grid-area: hamburger;
`

export default NavOpener
