let initialState = {
  playlistId: -1,
  trackId: -1
}

const PlayerSetCurrentTrackId = (state = initialState, action) => {
  switch (action.type) {
    case "PLAYER_SET_CURRENT_TRACK":
      return action.value
    case "PLAYER_HANDLE_CHANGE_TRACK":
      // console.log(action.playlists)
      // console.log(action.playlists.length)
      let currentPlaylist = action.playlists[state.playlistId].node
      let wantsToPreviousPlaylist = state.trackId + action.payload < 0
      let wantsToNextPlaylist = state.trackId + action.payload > currentPlaylist.content.tracks.length - 1
      // console.log("wantsToNextPlaylist", wantsToNextPlaylist)
      // console.log(currentPlaylist)
      if (wantsToPreviousPlaylist) {
        if (state.playlistId - 1 < 0) {
          return {
            playlistId: action.playlists.length - 1,
            trackId: action.playlists[action.playlists.length - 1].node.tracks.length - 1
          }
        } else {
          return {
            playlistId: state.playlistId - 1,
            trackId: action.playlists[state.playlistId - 1].node.tracks.length - 1
          }
        }
      } else if (wantsToNextPlaylist) {
        // wants to next playlist
        if (state.playlistId === action.playlists.length - 1) {
          return {
            playlistId: 0,
            trackId: 0
          }
        } else {
          // to first track of next playlist
          return {
            playlistId: state.playlistId + 1,
            trackId: 0
          }
        }
      } else {
        // stay in current playlist
        return {
          playlistId: state.playlistId,
          trackId: state.trackId + action.payload
        }
      }

    default:
      return state
  }
}
export default PlayerSetCurrentTrackId
