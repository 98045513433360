export function getCurrentTrack(playlists, currentTrackIds) {
  let currentPlaylist = playlists[currentTrackIds?.playlistId || 0]?.node
  let currentTrack = !!currentPlaylist?.content?.tracks?.length && currentPlaylist?.content.tracks[currentTrackIds?.trackId || 0]
  return currentTrack
}

export function getFutureShows(shows) {
  let futureShows = shows?.filter(show => isShowFuture(show)) || []
  return futureShows
}

export function getStartDate(show) {
  return show?.info?.eventStart ? new Date(show.info.eventStart) : null
}

export function isShowFuture(show) {
  show = show.node ? show.node : show
  let startDate = getStartDate(show)
  let isFuture = startDate >= new Date()
  return isFuture
}

export function getPlaylistFromTrack(track, playlists) {
  // find track in playlists
  playlists.forEach((playlist, playlistIndex) => {
    playlist.node.content.tracks.forEach((playlistTrack, playlistTrackIndex) => {
      if (playlistTrack.id === track?.id && !!track) {
        track.playlistIndex = playlistIndex
        track.playlistTrackIndex = playlistTrackIndex
      }
    })
  })
  return track
}
