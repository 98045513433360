import React from "react"
import styled from "styled-components"
import AnimatingLink from "../general/animatingLink"

function Logo() {
  return (
    <AnimatingLink to="/" aria-label="Home">
      <Svg
        version="1.1"
        viewBox="0 0 730.9 730.4"
        xmlns="http://www.w3.org/2000/svg"
        //style={{enableBackground: "new 0 0 198.5 198.7"}}
      >
        <path className="st1" d="m221.4 433.9h-133.3v-132.2h133.3l-14.1 65.9z" />
        <path className="st1" d="m81.1 402.4h-81.1v-80h81.1l-8.5 39.9z" />
        <path className="st1" d="m642.9 433.9h-133.4l14.1-66.4-14.1-65.9h133.4z" />
        <path className="st1" d="m730.9 402.4h-81.1l8.5-40.2-8.5-39.9h81.1z" />
        <path className="st1" d="m503.6 503h-275.7v-275.7h275.7z" />
        <path className="st1" d="m429.2 221.4-65.8-14.1-66.4 14.1v-133.3h132.3z" />
        <path className="st1" d="m408.6 81.1-39.9-8.5-40.2 8.5v-81.1h80v81.1z" />
        <path className="st1" d="m429.2 642.3h-132.2v-133.3l66.4 14.1 65.9-14.1z" />
        <path className="st1" d="m408.6 730.4h-80v-81.1l40.2 8.5 39.9-8.5z" />
        <path
          className="st1"
          d="m193.2 157.9c-7.6 0-14.6 2.5-20.4 6.6l-10.4-10.4c3.4-4.5 5.3-10 5.3-16 0-14.8-12-26.8-26.8-26.8s-26.8 12-26.8 26.8 12 26.8 26.8 26.8c5.1 0 9.8-1.4 13.8-3.9l10.7 10.8c-4.4 5.8-7 13-7 20.9 0 19.1 15.6 34.7 34.7 34.7s34.7-15.6 34.7-34.7-15.5-34.8-34.6-34.8z"
        />
        <path
          className="st1"
          d="m503.6 192.6c0 19.1 15.6 34.7 34.7 34.7s34.7-15.6 34.7-34.7c0-7.8-2.6-15-7-20.9l10.7-10.7c4 2.4 8.8 3.9 13.8 3.9 14.8 0 26.8-12 26.8-26.8s-12-26.8-26.8-26.8-26.8 12-26.8 26.8c0 6 2 11.6 5.3 16l-10.4 10.4c-5.7-4.2-12.8-6.6-20.4-6.6-19.1 0-34.6 15.6-34.6 34.7z"
        />
        <path
          className="st1"
          d="m227.9 532.1c0-19.1-15.6-34.7-34.7-34.7s-34.7 15.6-34.7 34.7c0 7.8 2.6 15 7 20.8l-10.7 10.7c-4-2.4-8.8-3.8-13.8-3.8-14.8 0-26.8 12-26.8 26.8s12 26.8 26.8 26.8 26.8-12 26.8-26.8c0-6-2-11.6-5.3-16l10.4-10.4c5.7 4.2 12.8 6.6 20.4 6.6 19 0 34.6-15.6 34.6-34.7z"
        />
        <path
          className="st1"
          d="m538.3 566.8c7.6 0 14.6-2.5 20.4-6.6l10.4 10.4c-3.3 4.5-5.3 10-5.3 16 0 14.8 12 26.8 26.8 26.8s26.8-12 26.8-26.8-12-26.8-26.8-26.8c-5.1 0-9.8 1.4-13.8 3.8l-10.7-10.7c4.4-5.8 7-13 7-20.8 0-19.1-15.6-34.7-34.7-34.7s-34.7 15.6-34.7 34.7 15.4 34.7 34.6 34.7z"
        />
        <g transform="translate(-671.5,-290.2)" strokeMiterlimit="10" strokeWidth="1.134">
          <g className="st2">
            <path className="st3" d="m922.1 701.1v-63.7h-4.6v-0.8l4.5-16h8.9v80.5z" />
            <path
              className="st3"
              d="m999 701.1h-42.9c-3.3 0-5.3-2.1-5.3-5.4v-20.9h8.8v18.3h35.9v-33.6h-44.1l2-39h49.5v7.9h-41.4l-1.3 23.4h38.7c3.3 0 5.3 2.2 5.3 5.5v38.4c0 3.3-2 5.4-5.2 5.4z"
            />
            <path
              className="st3"
              d="m1073.8 701.1h-44.8c-3.3 0-5.3-2.1-5.3-5.4v-69.8c0-3.3 2.1-5.4 5.3-5.4h44.8c3.3 0 5.4 2.1 5.4 5.4v69.8c0 3.3-2.1 5.4-5.4 5.4zm-3.4-72.6h-38v64.7h38z"
            />
            <path
              className="st3"
              d="m1149.8 701.1h-45.7c-3.3 0-5.3-2.1-5.3-5.4v-26.2c0-3.1 0.7-4.3 3.4-5.3l14.8-5-14.7-4.8c-2.8-1-3.4-2.3-3.4-5.3v-23.2c0-3.3 2.1-5.4 5.3-5.4h45.6c3.2 0 5.3 2.1 5.3 5.4v23.2c0 3.1-0.8 4.2-3.5 5.3l-14.5 4.8 14.7 5c2.8 0.9 3.5 2.2 3.5 5.3v26.2c-0.1 3.3-2.3 5.4-5.5 5.4zm-3.4-31.1-19.5-6.9-19.4 6.9v23.4h38.9zm-0.1-41.7h-38.7v20.2l19.3 7.1 19.4-7.1z"
            />
          </g>
          <path
            className="st3"
            d="m1052.1 665.9h-0.6c-3.1 0-5.7-2.5-5.7-5.7v-0.6c0-3.1 2.5-5.7 5.7-5.7h0.6c3.1 0 5.7 2.5 5.7 5.7v0.6c0 3.2-2.5 5.7-5.7 5.7z"
          />
        </g>
      </Svg>
    </AnimatingLink>
  )
}

const Svg = styled.svg`
  .st1 {
    fill: #000;
  }
  .st2 {
    enable-background: new;
  }
  .st3 {
    stroke: #fff;
    stroke-width: 4.4536;
    stroke-miterlimit: 10;
    fill: #fff;
  }
  .st4 {
    stroke: #fff;
    stroke-width: 2.9089;
    stroke-miterlimit: 10;
  }
  .st5 {
    stroke: #fff;
    fill: #fff;
    stroke-width: 2.9089;
    stroke-miterlimit: 10;
  }
`

export default Logo
