// 3rd party
import {useStaticQuery, graphql, Link} from "gatsby"
import React from "react"
import {useSelector} from "react-redux"
import styled, {css} from "styled-components"

// constants
import {colors, device, transitions} from "../constants/css"

function Announcer({isHome}) {
  let {settings} = useStaticQuery(query)
  let {announcement} = settings?.other
  const currentTrackIds = useSelector(state => state.playerCurrentTrackIds)
  let isPlayerInView = currentTrackIds.playlistId >= 0 && currentTrackIds.trackId >= 0
  return !!announcement?.toggle ? (
    <Link to={announcement.link.url} title={announcement.link.title} target={announcement.link.target}>
      <Outer $bgCol={announcement.backgroundColor} isPlayerInView={isPlayerInView}>
        <Inner isHome={isHome}>
          <Top>
            <h2>
              {announcement.title}
              {!!announcement.subtitle && (
                <>
                  <br />
                  {announcement.subtitle}
                </>
              )}
            </h2>
          </Top>
          {!!announcement.content && isHome && (
            <Bottom>
              <div dangerouslySetInnerHTML={{__html: announcement.content}} />
            </Bottom>
          )}
        </Inner>
      </Outer>
    </Link>
  ) : null
}

const Outer = styled.div`
  z-index: 1001;
  background-color: ${({$bgCol}) => $bgCol};
  color: ${colors.white};
  padding: 5px 10px;
  width: 280px;
  /*height: 200px;*/
  position: fixed;
  //top: 80px;
  bottom: 0;
  //bottom: 105px;
  //top: 20px;
  left: 50%;
  transform: translateX(-50%) translateY(${({isPlayerInView}) => (isPlayerInView ? 100 : 0)}%);
  transition: ${transitions.ornaments}ms;
  @media ${device.tabletS} {
    //left: auto;
    //top: 120px;
    bottom: auto;
    top: auto;
    transform: translateX(-50%);
  }
  @media ${device.laptop} {
    top: auto;
    transform: none;
    left: auto;
  }
`
const Inner = styled.div`
  border: 1px solid white;
  position: relative;
  height: 100%;
  width: 100%;
  p,
  * {
    text-align: center;
  }
  h2 {
    margin: 0;
  }
  ${({isHome}) =>
    isHome &&
    css`
      &:after {
        position: absolute;
        bottom: 5px;
        right: 0;
        transform: translateX(100%);
        content: "";
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 11px solid ${colors.white};
      }
    `}
`
const Top = styled.div`
  padding: 3px;
  max-height: 90px;
  overflow: hidden;
`
const Bottom = styled.div`
  display: none;
  visibility: hidden;
  @media ${device.laptop} {
    display: block;
    visibility: visible;
  }
  border-top: 1px solid ${colors.white};
  padding: 3px;
  * {
    font-size: 1.125rem;
  }
  strong {
    font-family: "Bigshot One", cursive;
    font-weight: 400;
  }
`

const query = graphql`
  query AnnouncementQuery {
    settings: wp {
      other: otherSettings {
        announcement: otherSettings {
          toggle: announcementToggle
          title: announcementTitle
          subtitle: announcementSubtitle
          content: announcementContent
          backgroundColor: announcementBackgroundColor
          link: announcementLink {
            target
            title
            url
          }
        }
      }
    }
  }
`
export default Announcer
