import styled, {css} from "styled-components"

const FadeIn = styled.div`
  opacity: 0;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation-name: fadeIn;
  animation-duration: 1250ms;
  animation-fill-mode: forwards;
  animation-delay: ${({delay = 0}) => delay}ms;
  ${({zIndex}) =>
    zIndex &&
    css`
      z-index: ${zIndex};
    `};
`

export default FadeIn
