// 3rd party
import React from "react"
import styled from "styled-components"
import {useSelector, useDispatch} from "react-redux"

// components
import Arrow from "../icons/arrow"

// actions
import {handleChangeTrack} from "../../actions/index"

function Skip({inverted = false, offset /*, isLoading*/}) {
  const playlists = useSelector(state => state.playlists)
  const dispatch = useDispatch()

  return (
    <DoubleButton
      onClick={() => /*!isLoading &&*/ dispatch(handleChangeTrack(playlists, offset))}
      aria-label={inverted ? "Previous track" : "Next track"}
    >
      <Arrow {...{inverted}} />
      <Arrow {...{inverted}} />
    </DoubleButton>
  )
}

const DoubleButton = styled.button`
  align-items: center;
  display: grid;
  padding: 3px;
  cursor: pointer;
  grid-auto-flow: column;
  grid-column-gap: 3px;
`

export default Skip
