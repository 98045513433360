// 3rd party
import React from "react"
import {Animate} from "react-move"

// constants
import {colors} from "../../constants/css"

function BurgerCircle({open}) {
  const percentage1 = open ? 0 : 100
  const percentage2 = open ? 100 : 0
  const delay = open ? 0 : 100
  const duration = open ? 200 : 200

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 204.4 204.4"
      style={{enableBackground: "new 0 0 204.4 204.4"}}
    >
      <defs>
        <Animate
          start={{offset: percentage1}}
          enter={{offset: [percentage1]}}
          update={{offset: [percentage1], timing: {delay: delay, duration: duration}}}
        >
          {state => (
            <linearGradient id="gradientLeft">
              <stop offset={state.offset + "%"} stopColor={colors.black} />
              <stop offset="0%" stopColor="transparent" />
            </linearGradient>
          )}
        </Animate>
        <Animate
          start={{offset: percentage2}}
          enter={{offset: [percentage2]}}
          update={{offset: [percentage2], timing: {delay: delay, duration: duration}}}
        >
          {state => (
            <linearGradient id="gradientRight">
              <stop offset={state.offset + "%"} stopColor="transparent" />
              <stop offset="0%" stopColor={colors.black} />
            </linearGradient>
          )}
        </Animate>
      </defs>
      <g>
        <path className="st0" d="M51.3,13.5c1.4-0.8,2.9-1.6,4.3-2.4C54.2,11.9,52.8,12.7,51.3,13.5z" />
        <path className="st0" d="M55.7,11.2c1.5-0.8,3-1.5,4.5-2.1C58.6,9.7,57.1,10.4,55.7,11.2z" />
        <path className="st0" d="M167.2,181.1c-1.3,1-2.5,2-3.9,3C164.7,183.1,165.9,182.1,167.2,181.1z" />
        <path className="st0" d="M62.4,8c1.5-0.6,3.1-1.3,4.6-1.8C65.5,6.8,63.9,7.4,62.4,8z" />
        <path className="st0" d="M74.2,3.9c1.6-0.5,3.3-0.9,4.9-1.3C77.5,3,75.8,3.4,74.2,3.9z" />
        <path className="st0" d="M201.8,125.3c-0.4,1.6-0.8,3.3-1.3,4.9C201,128.6,201.4,126.9,201.8,125.3z" />
        <path className="st0" d="M199,135c-1.1,3.2-2.3,6.3-3.7,9.3C196.7,141.2,197.9,138.1,199,135z" />
        <path className="st0" d="M184.1,163.3c-2,2.6-4,5.1-6.3,7.6C180,168.5,182.1,166,184.1,163.3z" />
        <path className="st0" d="M195.4,144.3c-1.4,3-2.9,6-4.5,8.8C192.5,150.2,194,147.3,195.4,144.3z" />
        <path className="st0" d="M190.8,153.1c-2,3.6-4.3,7-6.8,10.3C186.5,160.1,188.8,156.6,190.8,153.1z" />
        <path
          className="st0"
          d="M0,102.2c0-37.9,20.7-71,51.3-88.6C20.7,31.2,0,64.3,0,102.2c0,36.4,19,68.4,47.7,86.5l0,0
		C19,170.5,0,138.6,0,102.2z"
        />
        <path className="st0" d="M170.9,177.8c2.4-2.2,4.7-4.5,6.9-6.9C175.6,173.3,173.3,175.6,170.9,177.8z" />
        <path
          d="M0,102.2c0,36.4,19,68.4,47.7,86.5v-19.7C28.4,153.1,16,129.1,16,102.2C16,54.7,54.7,16,102.2,16c0.2,0,0.3,0,0.5,0V0
		c-0.2,0-0.3,0-0.5,0c-7.9,0-15.7,0.9-23.1,2.6c-1.7,0.4-3.3,0.8-4.9,1.3c-2.4,0.7-4.8,1.5-7.2,2.3c-1.6,0.6-3.1,1.2-4.6,1.8
		c-0.8,0.3-1.5,0.7-2.3,1c-1.5,0.7-3,1.4-4.5,2.1s-2.9,1.5-4.3,2.4C20.7,31.2,0,64.3,0,102.2z"
        />
        <path
          d="M102.7,0v16c47.3,0.3,85.7,38.8,85.7,86.2c0,26.4-11.9,50-30.7,65.9V188c1.9-1.3,3.8-2.5,5.6-3.9c1.3-1,2.6-2,3.9-3
		s2.5-2.1,3.7-3.2c2.4-2.2,4.7-4.5,6.9-6.9c2.2-2.4,4.3-5,6.3-7.6c2.5-3.3,4.7-6.7,6.8-10.3c1.6-2.9,3.2-5.8,4.5-8.8
		s2.6-6.1,3.7-9.3c0.5-1.6,1-3.2,1.5-4.8s0.9-3.3,1.3-4.9c1.7-7.4,2.6-15.1,2.6-23.1C204.4,45.9,158.9,0.3,102.7,0z"
        />
        <g>
          <path d="M47.7,188.6L47.7,188.6c1.2,0.8,2.4,1.5,3.6,2.2C50.1,190.1,48.9,189.4,47.7,188.6z" />
        </g>
        <g>
          <path d="M67,198.2c-1.6-0.6-3.1-1.2-4.6-1.8C63.9,197,65.5,197.6,67,198.2z" />
        </g>
        <g>
          <path d="M79.1,201.8c-1.7-0.4-3.3-0.8-4.9-1.3C75.8,201,77.5,201.4,79.1,201.8z" />
        </g>
        <g>
          <path d="M55.7,193.2c-1.5-0.8-2.9-1.5-4.3-2.4C52.8,191.7,54.2,192.5,55.7,193.2z" />
        </g>
        <g>
          <path d="M60.1,195.4c-1.5-0.7-3-1.4-4.5-2.1C57.1,194,58.6,194.7,60.1,195.4z" />
        </g>
        <g>
          <path
            className="st1"
            fill="url('#gradientLeft')"
            d="M47.7,168.9v19.7c1.2,0.8,2.4,1.5,3.6,2.2c1.4,0.8,2.9,1.6,4.3,2.4c1.5,0.8,3,1.5,4.5,2.1
			c0.8,0.3,1.5,0.7,2.3,1c1.5,0.6,3.1,1.3,4.6,1.8c2.3,0.9,4.7,1.6,7.2,2.3c1.6,0.5,3.3,0.9,4.9,1.3c7.4,1.7,15.1,2.6,23.1,2.6
			c0.2,0,0.3,0,0.5,0v-16c-0.2,0-0.3,0-0.5,0C81.5,188.4,62.5,181.1,47.7,168.9z"
          />
        </g>
        <g>
          <path d="M125.3,201.8c1.7-0.4,3.3-0.8,4.9-1.3C128.5,201,126.9,201.4,125.3,201.8z" />
        </g>
        <g>
          <path d="M137.3,198.2c1.6-0.6,3.1-1.2,4.6-1.8C140.4,197,138.9,197.6,137.3,198.2z" />
        </g>
        <g>
          <path
            className="st2"
            fill="url('#gradientRight')"
            d="M102.7,188.4v16c7.8,0,15.3-0.9,22.6-2.6c1.7-0.4,3.3-0.8,4.9-1.3c2.4-0.7,4.8-1.5,7.2-2.3
			c1.6-0.6,3.1-1.2,4.6-1.8c5.5-2.3,10.8-5.1,15.7-8.4v-19.9C142.8,180.6,123.6,188.3,102.7,188.4z"
          />
        </g>
      </g>
    </svg>
  )
}

export default BurgerCircle
