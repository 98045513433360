import React from "react"
import {Provider} from "react-redux"
import createStore from "./src/state/createStore"
//import Layout from "./src/layouts/index"

// eslint-disable-next-line react/display-name,react/prop-types
function WrapWithProvider({element}) {
  //console.log(props.location.pathname === "/")
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore()
  return (
    <Provider store={store}>
      {element}
      {/*<Layout>{element}</Layout>*/}
    </Provider>
  )
}
export default WrapWithProvider
