// 3rd party
import React from "react"
import styled from "styled-components"
import {useDispatch} from "react-redux"

// actions
import {setIsMenuOpen} from "../actions/index"
import AnimatingLink from "./general/animatingLink"
import {device} from "../constants/css"

function MainLink({to, text, active = false, children}) {
  const dispatch = useDispatch()
  return (
    <AnimatingLink to={to} onClick={() => dispatch(setIsMenuOpen(false))} aria-label={text}>
      <Shifter active={active}>
        <H2>{text}</H2>
      </Shifter>
      {children}
    </AnimatingLink>
  )
}

const H2 = styled.h2`
  letter-spacing: 1px;
  text-transform: lowercase;
  font-family: "Bigshot One", cursive;
  font-size: 1.25rem;
  font-weight: 400;
  margin: 3px 0;
`

const Shifter = styled.div`
  @media ${device.tabletL} {
    transition: all 600ms ease-out;
    transform: ${({active}) => (active ? "translate(90px)" : "none")};
  }
`

export default MainLink
