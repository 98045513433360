// 3rd party
import {combineReducers} from "redux"

// actions
import ControllerReducer from "./controller"
import MenuReducer from "./menu"
import HighlightedPlaylistReducer from "./highlightedPlaylist"
import PlaylistCountReducer from "./playlistCount"
import PlaylistsReducer from "./playlists"
import TrackReducer from "./track"
import PlayerVolumeReducer from "./playerVolume"
import PlayerProgressReducer from "./playerProgress"
import PlayerIsPlayingReducer from "./playerIsPlaying"
import PlayerCurrentTrackReducer from "./playerCurrentTrack"
import WidgetLoadingReducer from "./widgetLoading"
import PlayerSeekReducer from "./playerSeek"
import HomeReducer from "./homeReducer"
import ModalImageReducer from "./modalImage"
import ModalOpenReducer from "./modalOpen"

const allReducers = combineReducers({
  highlightedPlaylist: HighlightedPlaylistReducer,
  isMenuOpen: MenuReducer,
  isControllerMinimized: ControllerReducer,
  highlightedTracks: TrackReducer,
  isHome: HomeReducer,
  playlistCount: PlaylistCountReducer,
  playlists: PlaylistsReducer,
  playerVolume: PlayerVolumeReducer,
  playerProgress: PlayerProgressReducer,
  playerIsPlaying: PlayerIsPlayingReducer,
  playerSeekProgress: PlayerSeekReducer,
  playerCurrentTrackIds: PlayerCurrentTrackReducer,
  isWidgetLoading: WidgetLoadingReducer,
  modalImage: ModalImageReducer,
  modalIsOpen: ModalOpenReducer
})

export default allReducers
