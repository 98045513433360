const TrackReducer = (state = [0], action) => {
  switch (action.type) {
    case "MOVE_HORIZONTALLY":
      return state.map((item, index) => {
        // Replace the item at index 2
        if (index === action.playlist) {
          return item + action.payload
        }
        return item
      })
    case "TRACKS_INITIALIZE":
      return action.value
    default:
      return state
  }
}
export default TrackReducer
