// 3rd party
import React from "react"
import styled from "styled-components"
import {useSelector} from "react-redux"
import {useStaticQuery, graphql} from "gatsby"

// components
import MainLink from "../mainLink"

function Header() {
  let {menu} = useStaticQuery(query)
  const isMenuOpen = useSelector(state => state.isMenuOpen)
  let menuItems = menu.items.nodes.sort((a, b) => a.order - b.order) // guarantee menu order
  return (
    <NavContainer visible={isMenuOpen}>
      <StyledNav visible={isMenuOpen}>
        {menuItems.map(menuItem => {
          return (
            <Li key={menuItem.url}>
              <MainLink to={menuItem.url} text={menuItem.text} />
            </Li>
          )
        })}
      </StyledNav>
    </NavContainer>
  )
}

const Li = styled.li`
  line-height: 1;
`

const NavContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: calc(50% - 3px);
  transition: 0.3s;
  visibility: ${({visible}) => (visible ? "visible" : "hidden")};
  opacity: ${({visible}) => (visible ? 1 : 0)};
  transform: translateY(100%);
  overflow: hidden;
`

const StyledNav = styled.ul`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: max-content;
  grid-row-gap: 0px;
  text-align: right;
  padding-top: 15px;
  transition: 0.3s;
  transform: ${({visible}) => (visible ? "translateY(0%)" : "translateY(-40%)")};
`

const query = graphql`
  {
    menu: wpMenu(locations: {eq: HEADER_MENU}) {
      items: menuItems {
        nodes {
          url
          text: label
          order
        }
      }
    }
  }
`

export default Header
