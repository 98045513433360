import styled from "styled-components"
import {colors, device} from "../../../constants/css"

const ControllerLi = styled.li`
  top: 50%;
  left: 50%;
  will-change: left, transform;
  border-radius: 2%;
  opacity: ${({visible}) => (visible ? 1 : 0)};
  transform-origin: 50% 50%;
  overflow: hidden;
  position: absolute;
  list-style: none;
  background-color: ${colors.black};
  z-index: ${({zIndex}) => zIndex};
  align-self: center;
  justify-self: center;

  // height, width
  height: ${({sizes}) => sizes.small}px;
  width: ${({sizes}) => sizes.small}px;
  @media ${device.tabletM} {
    height: ${({sizes}) => sizes.medium}px;
    width: ${({sizes}) => sizes.medium}px;
  }
  @media ${device.tabletL} {
    height: ${({sizes}) => sizes.large}px;
    width: ${({sizes}) => sizes.large}px;
  }
`

export default ControllerLi
