// 3rd party
import React, {useEffect} from "react"
import {graphql} from "gatsby"
import {useDispatch} from "react-redux"
import styled from "styled-components"

// components
import Seo from "../components/general/seo"
import Scrollbar from "../components/general/scrollbar"
import Socials from "../components/sections/socials"
import ProductsList from "../components/lists/productsList"
import TracksList from "../components/sections/tracksList"
import ShowsList from "../components/lists/showsList"
import PageTitle from "../components/general/pageTitle"
import PageGrid from "../components/styled/pageGrid"
import ImageGallery from "../components/sections/imageGallery"
import {setIsHome} from "../actions"
import {getFutureShows} from "../utils/dataManipulation"
import AnimatingLink from "../components/general/animatingLink"

const Artist = ({data, transitionStatus}) => {
  let {artist, settings} = data
  let socials = artist.info.socials
  let {products, tracks, shows} = artist.info
  let futureShows = getFutureShows(shows)
  const dispatch = useDispatch()
  useEffect(() => {
    artist.seo.metaDesc = artist.seo.metaDesc || artist.info.shortDescription
    dispatch(setIsHome(false))
  }, [data, dispatch])

  return (
    <>
      <Seo seo={artist.seo} />
      <PageGrid className={"page-" + transitionStatus}>
        <div>
          {settings.other.all.artistPage && (
            <PageTitle to={settings.other.all.artistPage.url}>{settings.other.all.artistPage.title}</PageTitle>
          )}
          {artist?.name && <h1>{artist.name}</h1>}
        </div>
        <Scrollbar>
          <section>
            <div dangerouslySetInnerHTML={{__html: artist?.content}} />
            {artist.featuredImage?.node && <ImageGallery items={[artist.featuredImage.node]} />}
            {socials && <Socials {...{socials}} prefix="Artist_Artistdetails_Socials_" />}
          </section>
          {!!tracks?.length && (
            <section>
              <H2>{settings.other.all.tracksLabel}</H2>
              <TracksList tracks={tracks} />
            </section>
          )}
          {!!products?.length && (
            <section>
              {settings.other.all.shopPage && (
                <AnimatingLink to={settings.other.all.shopPage.url}>
                  <H2>{settings.other.all.shopPage.title}</H2>
                </AnimatingLink>
              )}
              <ProductsList products={products} isSmall={true} />
            </section>
          )}
          {!!futureShows?.length && (
            <section>
              {settings.other.all.showsPage && (
                <AnimatingLink to={settings.other.all.showsPage.url}>
                  <H2>{settings.other.all.showsPage.title}</H2>
                </AnimatingLink>
              )}
              <ShowsList shows={futureShows} />
            </section>
          )}
        </Scrollbar>
      </PageGrid>
    </>
  )
}

const H2 = styled.h2`
  text-transform: lowercase;
`

export const query = graphql`
  query ArtistQuery($id: Int!) {
    settings: wp {
      other: otherSettings {
        all: otherSettings {
          tracksLabel
          artistPage {
            title
            url
          }
          shopPage {
            title
            url
          }
          showsPage {
            title
            url
          }
        }
      }
    }
    artist: wpArtist(databaseId: {eq: $id}) {
      seo {
        title
        metaDesc
      }
      id
      name: title
      content
      featuredImage {
        node {
          thumb: localFile {
            childImageSharp {
              gatsbyImageData(width: 400, placeholder: BLURRED)
            }
          }
          large: localFile {
            childImageSharp {
              gatsbyImageData(width: 800, placeholder: BLURRED)
            }
          }
        }
      }
      info: artistDetails {
        shortDescription
        tracks: trackArtist {
          ... on WpTrack {
            id: databaseId
            title
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 280, placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
        shows: showArtists {
          ... on WpShow {
            id
            title
            info: showInfo {
              content
              eventStart
              eventUrl
              soldOut
            }
          }
        }
        products: productArtist {
          ... on WpProduct {
            id
            title
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 80, placeholder: BLURRED)
                  }
                }
              }
            }
            info: productInfo {
              price
              productUrl
              track {
                ... on WpTrack {
                  id: databaseId
                }
              }
            }
          }
        }
        socials {
          ... on WpArtist_Artistdetails_Socials_Spotify {
            type: fieldGroupName
            url
          }
          ... on WpArtist_Artistdetails_Socials_Youtube {
            type: fieldGroupName
            url
          }
          ... on WpArtist_Artistdetails_Socials_Soundcloud {
            type: fieldGroupName
            url
          }
          ... on WpArtist_Artistdetails_Socials_Instagram {
            type: fieldGroupName
            url
          }
          ... on WpArtist_Artistdetails_Socials_Facebook {
            type: fieldGroupName
            url
          }
          ... on WpArtist_Artistdetails_Socials_Tiktok {
            type: fieldGroupName
            url
          }
          ... on WpArtist_Artistdetails_Socials_Website {
            type: fieldGroupName
            url
          }
        }
      }
    }
  }
`

export default Artist
