// 3rd party
import React, {useState} from "react"
import styled from "styled-components"
import {GatsbyImage, getImage} from "gatsby-plugin-image"

// components
import AnimatingLink from "../general/animatingLink"
import MainLink from "../mainLink"
import MainTwoCol from "../styled/mainTwoCol"
import sizes, {device} from "../../constants/css"

function ArtistMenuItem({artist}) {
  let [visible, setVisible] = useState(false)
  const image = getImage(artist.featuredImage?.node.localFile)

  return (
    <Li onMouseOver={() => setVisible(true)} onMouseLeave={() => setVisible(false)}>
      <MainTwoCol>
        <div>
          <div className="relative">
            <MainLink to={artist.uri} text={artist.name} active={visible} />
            <AnimatingLink to={artist.uri}>
              <ImgWrapper visible={visible}>{image && <GatsbyImage image={image} alt={artist.name} />}</ImgWrapper>
            </AnimatingLink>
          </div>
          <AnimatingLink to={artist.uri}>
            <Description>{artist.info.shortDescription}</Description>
          </AnimatingLink>
        </div>
      </MainTwoCol>
    </Li>
  )
}

const ImgWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.3s ease-out;
  transform: translateX(calc(100% + ${sizes.header.spacing}px));
  @media ${device.tabletL} {
    top: auto;
    bottom: 0;
    opacity: ${({visible}) => (visible ? 1 : 0)};
    transform: translateX(calc(100% + ${sizes.header.spacing}px)) translateY(calc(100% + ${({visible}) => (visible ? 0 : -30)}px));
  }
`
const Description = styled.p`
  text-align: right;
`

const Li = styled.li`
  margin-bottom: 10px;
  min-height: 100px;
  @media ${device.tabletL} {
    min-height: auto;
  }
`

export default ArtistMenuItem
