// 3rd party
import React, {useEffect, useState, useRef} from "react"
import styled from "styled-components"
import {useDispatch, useSelector} from "react-redux"
//import axios from "axios"

// constants, utils, actions, context
import {sizes, colors} from "../../constants/css.js"
import {debounce, resampleAndNormalizeWave} from "../../utils/helpers"
import {setProgress, seekPlayer, setIsPlaying} from "../../actions/index"
import {getCurrentTrack} from "../../utils/dataManipulation.js"

const drawLineSegment = (ctx, x, y, width, isPlayed) => {
  ctx.lineWidth = width - 1 // how thick the line is
  ctx.strokeStyle = isPlayed ? colors.white : colors.black // what color our line is
  ctx.shadowColor = "rgba(0, 0, 0, 0.35)"
  ctx.shadowOffsetX = 1
  ctx.shadowOffsetY = 1
  ctx.shadowBlur = 1
  ctx.beginPath()
  //y = isEven ? y : -y
  ctx.moveTo(x, 0)
  ctx.lineTo(x, -1 * y)
  //ctx.arc(x + width / 2, y, width / 2, Math.PI, 0, isEven)
  //ctx.lineTo(x + width, 0)
  ctx.stroke()
}

function PlayerWave() {
  const dispatch = useDispatch()
  const playlists = useSelector(state => state.playlists)
  const playerProgress = useSelector(state => state.playerProgress)
  const currentTrackIds = useSelector(state => state.playerCurrentTrackIds)
  let currentTrack = getCurrentTrack(playlists, currentTrackIds)
  const [wave, setWave] = useState({samples: []})
  const [samples, setSamples] = useState()
  const ref = useRef()
  const canvasRef = useRef()

  // stackoverflow  https://stackoverflow.com/questions/22073716/create-a-waveform-of-the-full-track-with-web-audio-api/41129912

  // effects
  useEffect(() => {
    let url = currentTrack?.info?.waveformFile?.localFile?.publicURL
    if (!!!url) return
    fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    })
      .then(res => {
        return res.json()
      })
      .then(myJson => {
        setWave(myJson.samples)
      })
  }, [currentTrack])

  useEffect(() => {
    if (wave) {
      let width = ref.current ? Math.round(ref.current.offsetWidth) : 0
      setSamples(resampleAndNormalizeWave(wave, width))
    }
  }, [ref, wave])

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      let width = ref.current ? ref.current.offsetWidth : 0
      if (wave) {
        setSamples(resampleAndNormalizeWave(wave, width))
      }
    }, 500)
    const isSSR = typeof window === "undefined"
    if (!isSSR) {
      window.addEventListener("resize", debouncedHandleResize)
      return _ => {
        window.removeEventListener("resize", debouncedHandleResize)
      }
    }
  }, [ref, wave])

  function handleTrackClick(e) {
    let currentTargetRect = e.currentTarget.getBoundingClientRect()
    const event_offsetX = e.pageX - currentTargetRect.left
    let trackProgress = event_offsetX / currentTargetRect.width
    dispatch(seekPlayer(trackProgress))
    dispatch(setProgress(trackProgress))
    dispatch(setIsPlaying(true))
  }

  useEffect(() => {
    if (!samples?.length) return
    // Set up the canvas
    const canvas = canvasRef.current
    canvas.style.width = "100%"
    canvas.style.height = sizes.waveHeight + "px" //"45px" // "100%"
    // ...then set the internal size to match
    const dpr = window.devicePixelRatio || 1
    const padding = 0 //20
    canvas.width = canvas.offsetWidth * dpr
    canvas.height = (canvas.offsetHeight + padding * 2) * dpr
    const ctx = canvas.getContext("2d")
    ctx.scale(dpr, dpr)
    ctx.translate(0, canvas.offsetHeight) // Set Y = 0 to be in the middle of the canvas

    // draw the line segments
    const width = canvas.offsetWidth / samples.length
    for (let i = 0; i < samples.length; i++) {
      const x = width * i
      let height = samples[i] * canvas.offsetHeight - padding
      drawLineSegment(ctx, x, height, width, i + 1 <= Math.ceil(samples.length * playerProgress))
    }
  }, [samples, playerProgress])

  return (
    <Waveform ref={ref} onClick={event => handleTrackClick(event)}>
      <Canvas ref={canvasRef} />
    </Waveform>
  )
}

const Canvas = styled.canvas`
  max-height: ${sizes.waveHeight}px;
`

const Waveform = styled.button`
  grid-area: main;
  line-height: 0;
`

export default PlayerWave
