// 3rd party
import React, {useState, useEffect} from "react"
import styled from "styled-components"

// components/css
import Ornament from "./ornament"
import {transitions, device, controllerSizes} from "../../constants/css"

function Ornaments() {
  const [isCollapsed, setIsCollapsed] = useState(false)
  useEffect(() => {
    setTimeout(() => setIsCollapsed(true), 1000)
  }, [isCollapsed])
  return (
    <div>
      <OrnamentLeftTop isCollapsed={isCollapsed}>
        <Ornament />
      </OrnamentLeftTop>
      <OrnamentRightTop isCollapsed={isCollapsed}>
        <Ornament />
      </OrnamentRightTop>
      <OrnamentLeftBottom isCollapsed={isCollapsed}>
        <Ornament />
      </OrnamentLeftBottom>
      <OrnamentRightBottom isCollapsed={isCollapsed}>
        <Ornament />
      </OrnamentRightBottom>
    </div>
  )
}

const OrnamentContainer = styled.div`
  width: ${controllerSizes.sizes.small * controllerSizes.ornamentRatio}px;
  @media ${device.tabletM} {
    width: ${controllerSizes.sizes.medium * controllerSizes.ornamentRatio}px;
  }
  @media ${device.laptop} {
    width: ${controllerSizes.sizes.large * controllerSizes.ornamentRatio}px;
  }
  position: absolute;
  left: 50%;
  top: 50%;
  transition: all ${transitions.ornaments}ms;
`

const OrnamentLeftTop = styled(OrnamentContainer)`
  transform: ${({isCollapsed}) =>
    !isCollapsed
      ? `translateX(${-controllerSizes.ornamentTranslate - 50}%) translateY(${-controllerSizes.ornamentTranslate - 50}%)`
      : `translate(-50%)`};
`

const OrnamentLeftBottom = styled(OrnamentContainer)`
  transform: ${({isCollapsed}) =>
    (!isCollapsed
      ? `translateX(${-controllerSizes.ornamentTranslate - 50}%) translateY(${controllerSizes.ornamentTranslate - 50}%)`
      : `translate(-50%)`) + "scaleY(-1)"};
`

const OrnamentRightTop = styled(OrnamentContainer)`
  transform: ${({isCollapsed}) =>
    (!isCollapsed
      ? `translateX(${controllerSizes.ornamentTranslate - 50}%) translateY(${-controllerSizes.ornamentTranslate - 50}%)`
      : `translate(-50%)`) + "scaleX(-1)"};
`

const OrnamentRightBottom = styled(OrnamentContainer)`
  transform: ${({isCollapsed}) =>
    (!isCollapsed
      ? `translateX(${controllerSizes.ornamentTranslate - 50}%) translateY(${controllerSizes.ornamentTranslate - 50}%)`
      : `translate(-50%)`) + "scale(-1)"};
`

export default Ornaments
