// 3rd party
import {useStaticQuery, graphql} from "gatsby"
import React from "react"

// components
import ArtistMenuItem from "../lists/artistMenuItem"

function ArtistsList() {
  const {artists} = useStaticQuery(query)

  return (
    <ul>
      {artists.edges.map(({node: artist}) => {
        return <ArtistMenuItem key={artist.id} {...{artist}} />
      })}
    </ul>
  )
}

export const query = graphql`
  query allArtists {
    artists: allWpArtist {
      edges {
        node {
          id: databaseId
          name: title
          uri
          info: artistDetails {
            shortDescription
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 80, height: 80, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ArtistsList
