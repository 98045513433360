import React, {useState} from "react"
import styled from "styled-components"
import {colors} from "../../constants/css"
//import {Slider} from "@material-ui/core"
import Slider from "@material-ui/core/Slider"
//import {createMuiTheme} from "@material-ui/core/styles"
//import {withStyles, makeStyles} from "@material-ui/core/styles"
import {withStyles} from "@material-ui/core/styles"
//import {ThemeProvider} from "@material-ui/styles"
import {setVolume} from "../../actions/index"
import {useDispatch, useSelector} from "react-redux"

const MySlider = withStyles({
  root: {
    //height: 2,
    //padding: "15px 0"
  },
  thumb: {
    height: 4,
    width: 20,
    color: colors.black,
    //backgroundColor: "red",
    //padding: 10,
    borderRadius: 2,
    marginBottom: "-4px !important",
    marginLeft: "-9px !important",
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit"
    }
    //marginBottom: "0 !important",
  },
  active: {},
  track: {
    width: 3,
    color: colors.black
  },
  rail: {
    width: 20,
    color: colors.white
  }
  /*
    markActive: {
      opacity: 1,
      backgroundColor: "currentColor"
    }*/
})(Slider)

function Volume() {
  const dispatch = useDispatch()
  const volume = useSelector(state => state.playerVolume)

  const [volumeOpen, setVolumeOpen] = useState(false)
  const [isDragging, setIsDragging] = useState(false)

  const handleChange = (event, newValue) => {
    dispatch(setVolume(newValue))
  }

  return (
    <VolumeWrapper
      onMouseEnter={() => setVolumeOpen(true)}
      onMouseLeave={() => setVolumeOpen(false)}
      onDragStart={() => setIsDragging(true)}
      onDragEnd={() => setIsDragging(false)}
    >
      <SliderWrapper visible={volumeOpen || isDragging}>
        <MySlider orientation="vertical" value={volume} onChange={handleChange} step={20} />
      </SliderWrapper>
      <StyledButton aria-label="Set volume">
        <Svg
          onClick={() => setVolumeOpen(!volumeOpen)}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 188.4 133.6"
          style={{enableBackground: "new 0 0 188.4 133.6"}}
        >
          <g>
            <path
              className="st0"
              d="M48.2,36.8H0v60.5h48.4c11.1,21.5,33.5,36.3,59.4,36.3V0C81.8,0,59.2,15,48.2,36.8z"
            />
            {volume > 50 && (
              <path
                className="st0"
                d="M121.7,0v19.5c26.3,0,47.6,21.3,47.6,47.6s-21.3,47.6-47.6,47.6v19c36.9,0,66.8-29.9,66.8-66.8
		S158.5,0,121.7,0z"
              />
            )}
            {volume > 0 && (
              <path className="st0" d="M155,66.4c0-18.8-15.2-34-34-34v68C139.8,100.4,155,85.2,155,66.4z" />
            )}
          </g>
        </Svg>
      </StyledButton>
    </VolumeWrapper>
  )
}

const VolumeWrapper = styled.div`
  position: relative;
`
const SliderWrapper = styled.div`
  visibility: ${({visible}) => (visible ? "visible" : "hidden")};
  height: 180px;
  position: absolute;
  top: 0;
  left: calc(50% + 3px);
  transform: translate(-50%, calc(-100% + 5px));
  padding: 100px 40px 8px 40px;
`

const Svg = styled.svg`
  width: 22px;
  .st0 {
  }
  .st1 {
  }
`
const StyledButton = styled.button`
  padding: 3px;
  cursor: pointer;
`

export default Volume
