// 3rd party
import React from "react"
import styled from "styled-components"
//import {useSelector} from "react-redux"

// components
import PlayPause from "./playPause"
import Skip from "./skip"

function Buttons() {
  //const isLoading = false //useSelector(state => state.isWidgetLoading)

  return (
    <StyledButtons /*isVisible={!isLoading}*/>
      <Skip offset={-1} inverted={true} /*{...{isLoading}}*/ />
      <PlayPause /*{...{isLoading}}*/ />
      <Skip offset={1} /*{...{isLoading}}*/ />
    </StyledButtons>
  )
}
const StyledButtons = styled.div`
  grid-area: buttons;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
  transition: 300ms;
  /*filter: blur(${({isVisible}) => (isVisible ? 0 : 5)}px);
  * {
    cursor: ${({isVisible}) => (isVisible ? "pointer" : "not-allowed")};
  }
  cursor: ${({isVisible}) => (isVisible ? "pointer" : "not-allowed")};*/
  grid-auto-columns: min-content;
`

export default Buttons
