import React, {useState} from "react"
import styled from "styled-components"
import ReactPlayer from "react-player/vimeo"
import {useStaticQuery, graphql} from "gatsby"
//import LittleVimeo from "@slightlyoff/lite-vimeo"
//import "@slightlyoff/lite-vimeo"
function BackgroundVideo() {
  const {settings} = useStaticQuery(query)
  //let videoId = "P2kGd-gr1g8"
  // https://www.youtube.com/watch?v=P2kGd-gr1g8&list=PLLccpoLGb8yaVJ9ZH5wKck-HdKUSFpF4i
  /* const videoOptions = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      //loop: 1,
      //playlist: videoId,
      autoplay: 1,
      mute: 1,
      controls: 0,
      disablekb: 1,
      rel: 0,
      modestbranding: 1,
      showinfo: 0
      ,
      onStateChange: event => {
        console.log(event)
        if (event.data === YouTube.PlayerState.ENDED) {
          event.target.playVideo()
        }
      }
    }
  }*/
  //let [player, setPlayer] = useState()
  let [isLoaded, setIsLoaded] = useState(false)
  return settings.other.all.videoLink ? (
    <VideoBackground>
      <VideoForeground isVisible={isLoaded}>
        <StyledReactPlayer
          url={settings.other.all.videoLink}
          playing={true}
          muted={true}
          loop={true}
          controls={false}
          //progressInterval={10000}

          //onReady={() => console.log("ready")}
          onProgress={e => {
            if (!isLoaded && e.played > 0.0) {
              //console.log("loaded")
              setIsLoaded(true)
            }
          }}
        />
      </VideoForeground>
    </VideoBackground>
  ) : null
}

const StyledReactPlayer = styled(ReactPlayer)`
  position: fixed;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  pointer-events: none;

  @media (min-aspect-ratio: 16/9) {
    height: 300% !important;
    top: -100%;
  }
  @media (max-aspect-ratio: 16/9) {
    width: 300% !important;
    left: -100%;
  }
` /*
const Video = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  opacity: ${({loading}) => (loading ? 0 : 1)};
  transition: opacity, 5s ease-in-out;
`*/
const VideoBackground = styled.div`
  transition: 3s 1s linear;
  /*opacity: ${({isVisible}) => (isVisible ? 1 : 0)};*/
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -99;
  &::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
`
const VideoForeground = styled.div`
  opacity: ${({isVisible}) => (isVisible ? 1 : 0)};
  transition: 1s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  @media (min-aspect-ratio: 16/9) {
    height: 300%;
    top: -100%;
  }
  @media (max-aspect-ratio: 16/9) {
    width: 300%;
    left: -100%;
  }
`

const query = graphql`
  query BackgroundQuery {
    settings: wp {
      other: otherSettings {
        all: otherSettings {
          videoLink
        }
      }
    }
  }
`

export default BackgroundVideo
