// 3rd party
import React, {useEffect} from "react"
import {useSelector, useDispatch} from "react-redux"

// components
import Arrow from "./icons/arrow"
import Pause from "./icons/pause"
import PlayCircle from "./icons/playCircle"
import PauseCircle from "./icons/pauseCircle"

// context/actions/constants
import {setIsPlaying, moveHorizontally, moveVertically, setCurrentTrackIds} from "../actions/index"
import {transitions} from "../constants/css"
import {getPlaylistFromTrack} from "../utils/dataManipulation"

function TrackReference({track, hasTitle = true, hasCircle = false}) {
  const highlightedTracks = useSelector(state => state.highlightedTracks)
  const highlightedPlaylist = useSelector(state => state.highlightedPlaylist)
  const currentTrackIds = useSelector(state => state.playerCurrentTrackIds)
  const isPlaying = useSelector(state => state.playerIsPlaying)
  const playlists = useSelector(state => state.playlists)
  const dispatch = useDispatch()

  // data
  let isCurrentTrack = track?.playlistTrackIndex === currentTrackIds.trackId
  let isCurrentPlaylist = track?.playlistIndex === currentTrackIds.playlistId
  let isCurrent = isCurrentPlaylist && isCurrentTrack

  // click handlers
  function handleTrackClick() {
    if (isCurrent) {
      handleCurrentTrackClick()
    } else {
      handleDifferentTrackClick()
    }
  }
  function handleCurrentTrackClick() {
    dispatch(setIsPlaying(!isPlaying))
  }

  function handleDifferentTrackClick() {
    let verticalDelta = track?.playlistIndex - highlightedPlaylist
    let needsToMoveVertically = !!verticalDelta
    let horizontalDelta = track?.playlistTrackIndex - highlightedTracks[track?.playlistIndex]
    let needsToMoveHorizontally = !!horizontalDelta
    if (Math.abs(verticalDelta) > 2 || Math.abs(horizontalDelta) > 2 || (needsToMoveVertically && needsToMoveHorizontally)) {
      // too large, move to track in two steps
      dispatch(moveVertically(verticalDelta))
      setTimeout(() => {
        dispatch(moveHorizontally(horizontalDelta, track.playlistIndex))
      }, transitions.controllerItem - 100)
    } else if (needsToMoveVertically) {
      dispatch(moveVertically(verticalDelta))
    } else {
      dispatch(moveHorizontally(horizontalDelta, track.playlistIndex))
    }
    dispatch(setIsPlaying(true))
    dispatch(setCurrentTrackIds({playlistId: track.playlistIndex, trackId: track.playlistTrackIndex}))
  }

  useEffect(() => {
    track = getPlaylistFromTrack(track, playlists)
  }, [track, playlists])

  return (
    <button onClick={() => handleTrackClick()} aria-label={isPlaying ? "Pause track" : "Play track"}>
      {(!isCurrent || !isPlaying) && (hasCircle ? <PlayCircle /> : <Arrow />)}
      {isCurrent && isPlaying && (hasCircle ? <PauseCircle /> : <Pause />)}
      {hasTitle && " " + track.title}
    </button>
  )
}

export default TrackReference
