// 3rd party
import {getImage, GatsbyImage} from "gatsby-plugin-image"
import React from "react"
import {useDispatch} from "react-redux"
import styled, {css} from "styled-components"

// actions
import {setModalImage, setModalIsOpen} from "../../actions"

// constants
import {device} from "../../constants/css"

function ImageGallery({items, inGrid = false}) {
  const dispatch = useDispatch()

  function handleClick(item) {
    dispatch(setModalIsOpen(true))
    dispatch(setModalImage(getImage(item?.large)))
  }

  return (
    <Ul {...{inGrid}}>
      {items?.map((item, i) => {
        const image = getImage(item.thumb)
        return (
          <li key={i}>
            <button aria-label="open image" onKeyDown={() => handleClick(item)} onClick={() => handleClick(item)}>
              {image && <GatsbyImage image={image} alt="" />}
            </button>
          </li>
        )
      })}
    </Ul>
  )
}

const Ul = styled.ul`
  ${({inGrid}) =>
    inGrid &&
    css`
      display: grid;
      width: 100%;
      justify-content: space-between;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 15px;
      @media ${device.mobileL} {
        li {
          //width: min-content;
        }
      }
    `}
`

export default ImageGallery
