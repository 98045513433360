// 3rd party
import React from "react"
import {useStaticQuery, graphql} from "gatsby"

// components
import ProductsList from "../lists/productsList"

function ProductsArchive() {
  const {products} = useStaticQuery(query)

  return <ProductsList products={products.edges} />
}

const query = graphql`
  query ShopQuery {
    products: allWpProduct {
      edges {
        node {
          id
          title
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 200, placeholder: BLURRED)
                }
              }
            }
          }
          info: productInfo {
            price
            productUrl
            track {
              ... on WpTrack {
                id: databaseId
              }
            }
            artist: productArtist {
              ... on WpArtist {
                id: databaseId
                name: title
                uri
              }
            }
          }
        }
      }
    }
  }
`

export default ProductsArchive
