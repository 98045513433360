// globalStyles.js
import {createGlobalStyle} from "styled-components"

const GlobalStyle = createGlobalStyle`
  html,
  body {
    margin: 0;
  }

  body {
    font-size: 0.875rem;
    overflow-x: hidden;
    font-family: "Lato", sans-serif;
    position: fixed;
    width: 100%;
  }
  * {
    font-family: "Lato", sans-serif;
  }

  input,
  textarea,
  button,
  select,
  a {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  main {
    min-height: calc(100vh - 320px);
    min-height: calc(var(--vh, 1vh) * 100 - 320px);
  }

  img {
    max-width: 100%;
    height: auto;
  }

  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: inherit;
  }
  a:hover {
    color: inherit;
  }

  * {
    box-sizing: border-box;
  }

  p {
    font-size: 0.875rem;
    line-height: 1.4;
    margin-bottom: 20px;
    text-align: justify;
    text-justify: distribute;
    text-justify: inter-character;
    /* https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  button {
    appearance: none;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    font-size: inherit;
  }

  p,
  ul,
  ol {
    margin: 0;
    padding: 0;
  }

  p {
    margin-bottom: 10px;
  }
  p:last-child {
    margin-bottom: 0;
  }

  li {
    line-height: 1.4;
  }

  h1 {
    font-family: "Bigshot One", cursive;
    font-weight: 400;
    margin: 0 0 10px 0;
    font-size: 1.375rem;
  }

  h2 {
    font-family: "Bigshot One", cursive;
    font-size: 1.125rem;
    font-weight: 400;
    margin: 0 0 10px 0;
  }

  h3 {
    font-size: 1.0625rem;
    font-weight: 400;
    margin: 0 0 7px 0;
  }

  h3 {
    font-size: 1rem;
    font-weight: 400;
  }

  h4 {
    font-size: 0.875rem;
  }

  ul {
    list-style: none;
  }

  table {
    border-spacing: 0;
    margin-bottom: 20px;
  }

  td {
    padding: 8px 0;
    line-height: 1.8;
  }

  strong {
    font-weight: 900;
  }

  section {
    padding: 15px 0;
  }

  #gatsby-focus-wrapper {
    height: 100%;
  }

  .relative {
    position: relative;
  }

  .fade-enter {
    opacity: 0 !important;
  }
  .fade-enter-active,
  .fade-enter-done {
    opacity: 1 !important;
  }

  .fade-exit-active {
    opacity: 0 !important;
  }

  .tl-edges, .tl-wrapper {
    height: 100%;
    overflow-x: visible !important;
    //overflow-y: hidden;
  }

  .page-entering {
    transition: 0ms;
    opacity: 0;
    filter: blur(25px);
    transform: scale(1.25);
  }

  .page-entered {
    transition: 500ms;
    filter: blur(0px);
    transform: scale(1);
  }

  .page-exiting {
    transition: 500ms;
    filter: blur(5px);
    transform: scale(.75);
    opacity: 0;
  }
`
export default GlobalStyle
