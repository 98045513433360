import React, {useEffect, useRef} from "react"

function CloseClickOutside({children, setIsOpen, isActive = true}) {
  const myRef = useRef()

  useEffect(() => {
    const handleClick = e => {
      if (myRef.current?.contains(e.target)) {
        // inside click
        return
      } else {
        // outside click
        setIsOpen()
      }
    }
    // add when mounted
    if (isActive) document.addEventListener("mousedown", handleClick)
    // return function to be called when unmounted
    return () => document.removeEventListener("mousedown", handleClick)
  }, [isActive, setIsOpen])

  return <div ref={myRef}>{children}</div>
}

export default CloseClickOutside
