import React from "react"
import styled, {css} from "styled-components"
import {transitions} from "../../constants/css"

function Triangle({visible, side, color, hoverColor, cursor, isActive}) {
  return (
    <Svg
      color={isActive ? hoverColor : color}
      {...{hoverColor, cursor, side, visible}}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 107.9 767.5"
      style={{enableBackground: "new 0 0 107.9 767.5"}}
    >
      <path d="M0,0l107.9,383.8L0,767.5V0z" />
    </Svg>
  )
}

const Svg = styled.svg`
  position: absolute;
  z-index: 5;
  visibility: ${({visible}) => (visible ? "visible" : "hidden")};
  cursor: ${({cursor}) => cursor};
  fill: ${({color}) => color};

  transition: fill 0.2s, transform ${transitions.controllerItem}ms;
  ${({side}) =>
    (side === "left" || side === "right") &&
    css`
      top: 0;
      height: 100%;
    `}
  ${({side}) =>
    side === "left" &&
    css`
      transform: ${({visible}) => (visible ? "" : "translateX(-100%)")};
      left: 0%;
    `}
  ${({side}) =>
    side === "right" &&
    css`
      transform: ${({visible}) => (visible ? "scaleX(-1);" : "translateX(100%) scaleX(-1);")};
      right: 0%;
    `}


  ${({side}) =>
    (side === "top" || side === "bottom") &&
    css`
      left: 0;
      height: 100%;
    `}
  ${({side}) =>
    side === "top" &&
    css`
      transform-origin: 0 0;
      transform: ${({visible}) => (visible ? "scaleX(-1) rotateZ(90deg)" : "scaleX(-1) rotateZ(90deg) translateX(-100%) ")};
      top: 0%;
    `}
  ${({side}) =>
    side === "bottom" &&
    css`
      transform-origin: 0 100%;
      transform: ${({visible}) => (visible ? "scaleX(-1) rotateZ(-90deg)" : "scaleX(-1) rotateZ(-90deg) translateX(-100%) ")};
      bottom: 0%;
    `}
`

export default Triangle
