// 3rd party
import React, {useEffect} from "react"
import loadable from "@loadable/component"
import {graphql} from "gatsby"
import {useDispatch} from "react-redux"

// components
import Seo from "../components/general/seo"
import PageGrid from "../components/styled/pageGrid"
import PageTitle from "../components/general/pageTitle"
import Scrollbar from "../components/general/scrollbar"
import {setIsHome} from "../actions"

// loadable components
const ArtistsList = loadable(() => import("../components/sections/artistsList"))
const ProductsArchive = loadable(() => import("../components/sections/productsArchive"))
const ShowsArchive = loadable(() => import("../components/sections/showsArchive"))
const Editor = loadable(() => import("../components/sections/editor"))
const Socials = loadable(() => import("../components/sections/socials"))
const ImageGallery = loadable(() => import("../components/sections/imageGallery"))

function Page({data, transitionStatus}) {
  let {page} = data
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setIsHome(page.content.isHomepage))
  }, [page, dispatch])

  return (
    <>
      <Seo seo={page.seo} />
      {!page.content.isHomepage && (
        <PageGrid className={"page-" + transitionStatus}>
          <PageTitle>{page.title}</PageTitle>
          <Scrollbar>
            {page.content?.sections?.map((component, i) => {
              let type = component.type.replace("page_Pagecontent_ContentSections_", "")
              let content
              switch (type) {
                case "ArtistsOverview":
                  content = <ArtistsList {...component} key={i} />
                  break
                case "ProductsOverview":
                  content = <ProductsArchive {...component} key={i} />
                  break
                case "ShowsOverview":
                  content = <ShowsArchive {...component} key={i} />
                  break
                case "Socials":
                  content = <Socials {...component} prefix="WpPage_Pagecontent_ContentSections_Socials_Socials" key={i} />
                  break
                case "TextEditor":
                  content = <Editor {...component} key={i} />
                  break
                case "ImageGallery":
                  content = <ImageGallery {...component} key={i} inGrid={true} />
                  break
                default:
                  break
              }
              return content
            })}
          </Scrollbar>
        </PageGrid>
      )}
    </>
  )
}

export const query = graphql`
  query PageQuery($id: Int!) {
    page: wpPage(databaseId: {eq: $id}) {
      seo {
        title
        metaDesc
      }
      id
      title
      content: pageContent {
        isHomepage
        sections: contentSections {
          ... on WpPage_Pagecontent_ContentSections_ArtistsOverview {
            type: fieldGroupName
          }
          ... on WpPage_Pagecontent_ContentSections_ProductsOverview {
            type: fieldGroupName
          }
          ... on WpPage_Pagecontent_ContentSections_ShowsOverview {
            type: fieldGroupName
          }
          ... on WpPage_Pagecontent_ContentSections_Socials {
            type: fieldGroupName
            socials {
              ... on WpPage_Pagecontent_ContentSections_Socials_Socials_Spotify {
                type: fieldGroupName
                url
              }
              ... on WpPage_Pagecontent_ContentSections_Socials_Socials_Youtube {
                type: fieldGroupName
                url
              }
              ... on WpPage_Pagecontent_ContentSections_Socials_Socials_Soundcloud {
                type: fieldGroupName
                url
              }
              ... on WpPage_Pagecontent_ContentSections_Socials_Socials_Instagram {
                type: fieldGroupName
                url
              }
              ... on WpPage_Pagecontent_ContentSections_Socials_Socials_Facebook {
                type: fieldGroupName
                url
              }
              ... on WpPage_Pagecontent_ContentSections_Socials_Socials_Tiktok {
                type: fieldGroupName
                url
              }
              ... on WpPage_Pagecontent_ContentSections_Socials_Socials_Website {
                type: fieldGroupName
                url
              }
            }
          }
          ... on WpPage_Pagecontent_ContentSections_TextEditor {
            content
            type: fieldGroupName
          }
          ... on WpPage_Pagecontent_ContentSections_ImageGallery {
            type: fieldGroupName
            items {
              thumb: localFile {
                childImageSharp {
                  gatsbyImageData(width: 200, placeholder: BLURRED)
                }
              }
              large: localFile {
                childImageSharp {
                  gatsbyImageData(width: 800, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Page
