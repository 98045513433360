// 3rd party
import React, {useState, useEffect} from "react"
import {TransitionGroup, CSSTransition} from "react-transition-group"
import styled from "styled-components"
import {useSelector} from "react-redux"

// components
import {transitions} from "../../constants/css"
import ControllerRow from "./controllerRow"
import {correctValue} from "../../utils/helpers"

let timeout = transitions.controllerRow
const ControllerRows = ({placeholder = false}) => {
  const highlightedPlaylist = useSelector(state => state.highlightedPlaylist)
  const playlistCount = useSelector(state => state.playlistCount)
  const highlightedTracks = useSelector(state => state.highlightedTracks)
  const [isControllerCollapsed, setIsControllerCollapsed] = useState(true)

  var indices = []
  let numberAround = playlistCount > 1 ? 2 : 0
  for (var i = highlightedPlaylist - numberAround; i <= highlightedPlaylist + numberAround; i++) {
    indices.push(i)
  }
  if (placeholder) {
    indices = [-2, -1, 0, 1, 2]
  }

  // effects
  useEffect(() => {
    setTimeout(() => setIsControllerCollapsed(false), 1500)
  }, [isControllerCollapsed])

  return (
    <List $placeholder={placeholder}>
      <TransitionGroup>
        {indices.map(i => {
          let row = placeholder ? i : highlightedPlaylist - i
          let className = ""
          if (row > 0) {
            className = "left"
          }
          if (row < 0) {
            className = "right"
          }
          return (
            <CSSTransition timeout={timeout} key={"row" + i} classNames={isControllerCollapsed ? "" : className}>
              <ControllerRow
                {...{row, placeholder, isControllerCollapsed}}
                rowI={i}
                activeTrack={highlightedTracks[correctValue(i, playlistCount)]}
              />
            </CSSTransition>
          )
        })}
      </TransitionGroup>
    </List>
  )
}

const List = styled.ul`
  list-style: none;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: ${({$placeholder}) => ($placeholder ? "-1" : "")};
`

export default React.memo(ControllerRows)
