// 3rd party
import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"

function AnimatingLink({to, children, ...props}) {
  let duration = 0.5
  return (
    <TransitionLink
      to={to}
      {...props}
      entry={{
        length: 0
      }}
      exit={{
        length: duration
      }}
    >
      {children}
    </TransitionLink>
  )
}

export default AnimatingLink
