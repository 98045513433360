import React from "react"

function PlayCircle() {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 91.6 91.6"
      style={{enableBackground: "new 0 0 91.6 91.6"}}
    >
      <g>
        <g>
          <path
            className="st0"
            d="M79.7,41.4c1.9,1.9,1.9,5,0,6.9L26.6,71V18.8L79.7,41.4z M91.6,45.8C91.6,20.5,71.1,0,45.8,0S0,20.5,0,45.8
			s20.5,45.8,45.8,45.8S91.6,71.1,91.6,45.8"
          />
        </g>
      </g>
    </svg>
  )
}
export default PlayCircle
