import React from "react"
import {sizes} from "../../constants/css"
import styled from "styled-components"

function Pause() {
  return (
    <Svg
      version="1.1"
      id="svg2"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{enableBackground: "new 0 0 24 24"}}
    >
      <g>
        <g>
          <rect x="16" width="8" height="24" />
          <rect width="8" height="24" />
        </g>
      </g>
    </Svg>
  )
}
const Svg = styled.svg`
  width: ${sizes.playerIcon}px;
`

export default Pause
