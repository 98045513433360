import React from "react"
import {colors} from "../../constants/css"
import Triangle from "../icons/triangle"

function TriangleButton({level, row, isItemCollapsed, isControllerCollapsed, placeholder, isActive}) {
  let visible = level !== 0 || row !== 0 ? 1 : 0
  visible = isItemCollapsed ? 0 : visible
  let color = placeholder && isControllerCollapsed ? colors.white : colors.black
  let hoverColor = placeholder ? colors.white : colors.red
  let cursor = placeholder ? "default" : "pointer"
  return (
    <>
      {level <= 0 && row === 0 && <Triangle {...{isActive, color, hoverColor, cursor, visible}} side="left" />}
      {level >= 0 && row === 0 && <Triangle {...{isActive, color, hoverColor, cursor, visible}} side="right" />}
      {(row < 0 || (level === 0 && row === 0)) && <Triangle {...{isActive, color, hoverColor, cursor, visible}} side="top" />}
      {(row > 0 || (level === 0 && row === 0)) && <Triangle {...{isActive, color, hoverColor, cursor, visible}} side="bottom" />}
    </>
  )
}

export default TriangleButton
