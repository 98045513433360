import React from "react"

function PauseCircle() {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 20 20"
      style={{enableBackground: "new 0 0 20 20"}}
    >
      <g id="Page-1">
        <g id="Icons-AV" transform="translate(0.000000, -85.000000)">
          <g id="pause-circle-fill" transform="translate(0.000000, 85.000000)">
            <path
              id="Shape"
              d="M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0L10,0z M9,14H7V6h2V14L9,14z M13,14h-2V6h2V14
				L13,14z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
export default PauseCircle
