// 3rd party
import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import styled from "styled-components"
import {useStaticQuery, graphql} from "gatsby"

// other
import GlobalStyles from "../styles/globalStyles"
import {setPlaylists, setPlaylistCount, initializeTracks} from "../actions/index"
import "normalize.css"

// components
import {device, sizes} from "../constants/css"
import Header from "../components/header"
import Controller from "../components/controller/controller"
import PlayerArea from "../components/player/playerArea"
import PageWrapper from "../components/general/pageWrapper"
import BackgroundVideo from "../components/general/backgroundVideo"
import Modal from "../components/general/modal"

function Layout({children}) {
  let {playlists} = useStaticQuery(query)
  const isHome = useSelector(state => state.isHome)
  const dispatch = useDispatch()

  function handleResize() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }

  useEffect(() => {
    function resizeAndScroll() {
      if (typeof window !== "undefined") {
        handleResize()
        window.addEventListener("resize", handleResize)
      }
    }
    resizeAndScroll()
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    /*dispatch(setPlaylists([playlists.edges[0]]))
    dispatch(setPlaylistCount(1))*/
    let orderedPlaylists = playlists.edges.sort((a, b) => new Date(b.node.date) - new Date(a.node.date)) // guarantee playlist order
    dispatch(setPlaylists(orderedPlaylists))
    dispatch(setPlaylistCount(orderedPlaylists.length))

    dispatch(initializeTracks(new Array(playlists.edges.length).fill(0)))
  }, [dispatch, playlists.edges])

  //const LoadableBackgroundVideo = Loadable(() => import("../components/general/backgroundVideo"))
  //const url = location?.pathname ? location.pathname : ""
  //let isHome = url === "/"
  return (
    <>
      <GlobalStyles />
      <BackgroundVideo />
      <FullPage>
        <Container>
          <Header {...{isHome}} />
          <Body>
            <PageWrapper {...{isHome}}>{children}</PageWrapper>
            <Controller {...{isHome}} />
            <Modal />
          </Body>
        </Container>
      </FullPage>
      <PlayerArea />
    </>
  )
}
const Body = styled.div`
  height: 100%;
`
const FullPage = styled.div`
  padding: 20px 20px 0 20px;
  @media ${device.tabletM} {
    padding: 20px 45px 0 20px;
  }
  @media ${device.laptop} {
    padding: 20px 20px 0 20px;
  }
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
`

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: ${sizes.containerSmall}px;
  margin: 0 auto;
  @media ${device.tabletL} {
    max-width: none;
  }
  @media ${device.laptopM} {
    width: ${sizes.container}px;
  }
  position: relative;
  display: grid;
  grid-template-areas: "header" "main";
  grid-template-rows: min-content minmax(0, 1fr);
  grid-template-columns: 100%;
  grid-row-gap: 20px;
  justify-content: space-between;
`

const query = graphql`
  query allPlaylists {
    playlists: allWpPlaylist {
      edges {
        node {
          id: databaseId
          date
          title
          content: playlistDetails {
            subtitle
            tracks: playlistTracks {
              ... on WpTrack {
                id: databaseId
                title
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 280, placeholder: BLURRED)
                      }
                    }
                  }
                }
                info: trackInfo {
                  track {
                    localFile {
                      publicURL
                    }
                  }
                  waveformFile {
                    localFile {
                      publicURL
                    }
                  }
                  artist: trackArtist {
                    ... on WpArtist {
                      name: title
                      uri
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Layout
