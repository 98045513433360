// 3rd party
import React from "react"
import styled from "styled-components"
import {GatsbyImage} from "gatsby-plugin-image"
import {CSSTransition} from "react-transition-group"
import {useDispatch, useSelector} from "react-redux"

// actions
import {setModalIsOpen} from "../../actions"

// components
import {colors} from "../../constants/css"
import CloseClickOutside from "./closeClickOutside"

function Modal() {
  const modalImage = useSelector(state => state.modalImage)
  const modalIsOpen = useSelector(state => state.modalIsOpen)
  const dispatch = useDispatch()

  return (
    <CSSTransition timeout={500} in={modalIsOpen} appear={true} unmountOnExit classNames="fade">
      <Wrapper>
        <div className="relative" style={{height: "100%"}}>
          <CloseClickOutside setIsOpen={() => dispatch(setModalIsOpen(false))}>
            <ImageWrapper>{modalImage && <GatsbyImage image={modalImage} alt="" />}</ImageWrapper>
          </CloseClickOutside>
          <Cross aria-label="Close modal" onClick={() => dispatch(setModalIsOpen(false))}>
            x
          </Cross>
        </div>
      </Wrapper>
    </CSSTransition>
  )
}

const Wrapper = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  transition: all 500ms;
`

const Cross = styled.button`
  position: fixed;
  top: 15px;
  right: 15px;
  font-size: 2rem;
  transform: scaleY(0.85);
  color: ${colors.white};
`

const ImageWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  height: 800px;
  max-width: 80vw;
  max-height: 80vh;
`

export default Modal
