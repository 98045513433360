export const controllerSizes = {
  sizes: {large: 280, medium: 200, small: 170},
  translates: [-112.5, -75, 0, 75, 112.5],
  ratios: [0.25, 0.5, 1, 0.5, 0.25],
  borderRatio: 0.03333333333333333,
  ornamentRatio: 0.4,
  ornamentTranslate: 180,
  exitTranslates: [40, 60, 0, 60, 40],
  exitRatio: 0.5
}
let totalRatios = controllerSizes.ratios.reduce((a, b) => a + b, 0)
controllerSizes.totals = {
  large: controllerSizes.sizes.large * totalRatios,
  medium: controllerSizes.sizes.medium * totalRatios,
  small: controllerSizes.sizes.small * totalRatios
}

export const sizes = {
  bar: 2,
  waveHeight: 40,
  playerPadding: 10,
  playerIcon: 12,
  barWidth: 2,
  pageWidth: 400,
  barSpacing: 1,
  container: 1000,
  containerSmall: 500,
  header: {
    height: 80,
    width: 80,
    spacing: 10
  }
}

sizes.playerHeight = sizes.waveHeight + 2 * sizes.playerPadding

export const transitions = {
  controllerItem: 500,
  controllerPlay: 500,
  ornaments: 1000
}

transitions.controllerRow = transitions.controllerItem

export const colors = {
  black: "#000",
  white: "#fff",
  red: "rgb(249, 0, 0)",
  orange: "#c40", //"#f50"
  cyan: "#00ffff",
  magenta: "#ff00ff",
  yellow: "#ffff00"
}

export const deviceSize = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 475,
  tabletS: 600,
  tabletM: 768,
  tabletL: 850,
  laptop: 1024,
  laptopM: 1084,
  laptopL: 1440,
  desktop: 2560
}

export const device = {
  /* https://jsramblings.com/how-to-use-media-queries-with-styled-components/ */
  mobileS: `(min-width: ${deviceSize.mobileS}px)`,
  mobileM: `(min-width: ${deviceSize.mobileM}px)`,
  mobileL: `(min-width: ${deviceSize.mobileL}px)`,
  tabletS: `(min-width: ${deviceSize.tabletS}px)`,
  tabletM: `(min-width: ${deviceSize.tabletM}px)`,
  tabletL: `(min-width: ${deviceSize.tabletL}px)`,
  laptop: `(min-width: ${deviceSize.laptop}px)`,
  laptopM: `(min-width: ${deviceSize.laptopM}px)`,
  laptopL: `(min-width: ${deviceSize.laptopL}px)`,
  desktop: `(min-width: ${deviceSize.desktop}px)`,
  desktopL: `(min-width: ${deviceSize.desktop}px)`
}

export default sizes
