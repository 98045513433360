// 3rd party
import React from "react"
import styled from "styled-components"
import {GatsbyImage, getImage} from "gatsby-plugin-image"

// components
import ControllerPlay from "./controllerPlay"
import {controllerSizes, colors, device} from "../../constants/css"

const ControllerItemInner = React.memo(({visible, albumCover}) => {
  const image = getImage(albumCover?.localFile)
  return (
    <LiInner>
      {image && <GatsbyImage image={image} alt="" />}
      {visible && <ControllerPlay {...{visible}} />}
    </LiInner>
  )
})

function getBorder(size) {
  return controllerSizes.sizes[size] * controllerSizes.borderRatio + "px solid " + colors.black
}

const LiInner = styled.div`
  position: relative;
  padding: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;

  // border
  border: ${getBorder("small")};
  @media ${device.tabletM} {
    border: ${getBorder("medium")};
  }
  @media ${device.laptop} {
    border: ${getBorder("large")};
  }
`

export default ControllerItemInner
