// 3rd party
import React, {useEffect, useCallback} from "react"
import styled from "styled-components"
import {useSelector, useDispatch} from "react-redux"

// components
import Ornaments from "./ornaments"
import ControllerRows from "./controllerRows"
import CloseClickOutside from "../general/closeClickOutside"
import FadeIn from "../styled/fadeIn"

// constants, utils, actions
import sizes, {device, controllerSizes} from "../../constants/css"
import {correctValue} from "../../utils/helpers"
import {setIsControllerMinimized, moveVertically, moveHorizontally} from "../../actions/index"

const Controller = React.memo(({isHome}) => {
  const dispatch = useDispatch()
  const isMenuOpen = useSelector(state => state.isMenuOpen)

  // selectors
  const highlightedPlaylist = useSelector(state => state.highlightedPlaylist)
  const playlistCount = useSelector(state => state.playlistCount)
  const isControllerMinimized = useSelector(state => state.isControllerMinimized)

  const keyHandler = useCallback(
    event => {
      let arrowKeys = [37, 38, 39, 40]
      if (arrowKeys.includes(event.keyCode)) {
        switch (event.keyCode) {
          case 37:
            dispatch(moveHorizontally(-1, correctValue(highlightedPlaylist, playlistCount)))
            return
          case 38:
            playlistCount > 1 && dispatch(moveVertically(-1))
            return
          case 39:
            dispatch(moveHorizontally(1, correctValue(highlightedPlaylist, playlistCount)))
            return
          case 40:
            playlistCount > 1 && dispatch(moveVertically(1))
            return
          default:
            return
        }
      }
    },
    [highlightedPlaylist, playlistCount, dispatch]
  )

  // effect
  useEffect(() => {
    document.addEventListener("keydown", keyHandler, false)
    return () => document.removeEventListener("keydown", keyHandler, false)
  }, [keyHandler])

  // https://codepen.io/andyNroses/pen/KaENLb?editors=0110
  return (
    <FadeIn>
      <Container
        blurred={isMenuOpen && isHome}
        minimized={isControllerMinimized && !isHome}
        onClick={e => e.preventDefault()}
        size={controllerSizes.totals}
      >
        <ContainerInner size={controllerSizes.totals}>
          <CloseClickOutside setIsOpen={() => dispatch(setIsControllerMinimized(true))} isActive={!isControllerMinimized}>
            <Ornaments />
            <ControllerRows placeholder={true} />
            <ControllerRows />
          </CloseClickOutside>
          <Overlay onClick={() => dispatch(setIsControllerMinimized(false))} isHidden={!isControllerMinimized} />
        </ContainerInner>
      </Container>
    </FadeIn>
  )
})

const ContainerInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const Container = styled.div`
  will-change: transform;
  z-index: 100;
  position: absolute;
  transition: all ${({minimized}) => (minimized ? "0.25s ease-out" : ".3s ease-in-out")};

  // top, left, transform
  filter: blur(${({blurred}) => (blurred ? "10px" : 0)});
  transform: translateX(-50%) translateY(${({minimized}) => (minimized ? "calc(var(--vh, 1vh) * -50 + 6px)" : "-50%")})
    scale(${({minimized}) => (minimized ? 0.2 : 1)});
  transform-origin: 50% 0%;
  top: 50%; //${({minimized}) => (minimized ? "-15px" : "50%")};
  left: 50%; // ${({minimized}) => (minimized ? "50%" : "50%")};
  @media ${device.tabletL} {
    transform-origin: 50% 50%;
    left: ${({minimized}) => (minimized ? "0%" : "50%")};
    transform: ${({minimized}) => (minimized ? "translateX(-50%) scale(.75)" : "translateX(-50%)")};
  }
  @media ${device.tabletL} {
    filter: none;
    transform-origin: 0% 50%;
    transform: ${({minimized}) => (minimized ? "scale(.5)" : "translateX(-50%)")};
    top: 80px;
  }
  @media ${device.laptop} {
    left: ${({minimized}) => (minimized ? `calc(100% - ${sizes.pageWidth}px)` : "50%")};
    transform: ${({minimized}) => (minimized ? "scale(.7) translateX(calc(-100% - 75px)) " : "translateX(-50%)")};
  }

  // width/height
  width: ${({size}) => size.small}px;
  height: ${({size}) => size.small}px;
  @media ${device.tabletM} {
    width: ${({size}) => size.medium}px;
    height: ${({size}) => size.medium}px;
  }
  @media ${device.tabletL} {
    width: ${({size}) => size.large}px;
    height: ${({size}) => size.large}px;
  }
`
const Overlay = styled.div`
  width: 100%;
  height: 100%;
  z-index: 101;
  position: relative;
  background-color: transparent;
  cursor: pointer;
  display: ${({isHidden}) => (isHidden ? "none" : "block")};
  @media ${device.tabletL} {
    display: none;
  }
`

export default Controller
