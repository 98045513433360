// 3rd party
import React from "react"
import styled, {css} from "styled-components"
import {useSelector, useDispatch} from "react-redux"

// other
import {setIsPlaying, setCurrentTrackIds} from "../../actions/index"
import {correctValue} from "../../utils/helpers"
import {colors, controllerSizes, device, transitions} from "../../constants/css"

// components
import PlayCircle from "../icons/playCircle"
import PauseCircle from "../icons/pauseCircle"
import AnimateBars from "./animateBars"
import Loader from "./styled/loader"

function ControllerPlay({visible}) {
  const isPlaying = useSelector(state => state.playerIsPlaying)
  const highlightedPlaylistId = useSelector(state => state.highlightedPlaylist)
  const highlightedTracksIds = useSelector(state => state.highlightedTracks)
  const isWidgetLoading = useSelector(state => state.isWidgetLoading)
  const playlists = useSelector(state => state.playlists)
  const currentTrackIds = useSelector(state => state.playerCurrentTrackIds)
  const dispatch = useDispatch()
  //const [notificationText, setNotificationText] = useState("test")
  let correctedHighlightedPlaylistId = correctValue(highlightedPlaylistId, playlists.length)
  let highlightedPlaylist = playlists[correctedHighlightedPlaylistId]?.node
  let highlightedTrackId = highlightedTracksIds[correctedHighlightedPlaylistId]
  let correctedHighlightedTrackId = correctValue(highlightedTrackId, highlightedPlaylist?.content?.tracks?.length)
  let highlightedTrack = highlightedPlaylist?.content?.tracks[correctedHighlightedTrackId]

  let isCurrentPlaylistId = correctedHighlightedPlaylistId === currentTrackIds.playlistId
  let isCurrentTrackId = correctedHighlightedTrackId === currentTrackIds.trackId
  let isCurrentTrack = isCurrentPlaylistId && isCurrentTrackId

  function clickHandler() {
    if (!isCurrentTrack) {
      dispatch(setCurrentTrackIds({playlistId: correctedHighlightedPlaylistId, trackId: correctedHighlightedTrackId}))
      //setNotificationText("is not current track")
    }
    if (isPlaying) {
      //setNotificationText("is playing")
      if (isCurrentTrack) {
        //setNotificationText("is playing current track")
        dispatch(setIsPlaying(false))
      } else {
        //setNotificationText("error")
      }
    } else {
      //setNotificationText("is not playing")
      // is current track but is not playing
      dispatch(setIsPlaying(true))
    }
  }
  return (
    <>
      {/*<Notification>{notificationText}</Notification>*/}
      <OuterCircle {...{visible}}>
        <InnerCircle>
          <Title $top {...{visible}}>
            <H2>{highlightedTrack?.info?.artist[0].name}</H2>
          </Title>
          <Title $bottom {...{visible}}>
            <H3>{highlightedTrack?.title}</H3>
          </Title>
          <ArrowContainer onClick={() => /*!isWidgetLoading &&*/ clickHandler()}>
            <Loader isLoading={isWidgetLoading}>
              {(!isPlaying || !isCurrentTrack) /*|| isWidgetLoading*/ && <PlayCircle />}
              {isPlaying && isCurrentTrack /*&& !isWidgetLoading */ && <PauseCircle />}
            </Loader>
          </ArrowContainer>
          <BarsWrapper>
            <AnimateBars width={189} height={60} nBars={10} animate={isPlaying && !isWidgetLoading} />
          </BarsWrapper>
        </InnerCircle>
      </OuterCircle>
    </>
  )
}

const Title = styled.div`
  position: absolute;
  left: 50%;
  ${({$top}) =>
    $top &&
    css`
      bottom: calc(50% + ${(controllerSizes.sizes.small / controllerSizes.sizes.large) * 35}px);
      @media ${device.tabletM} {
        bottom: calc(50% + ${(controllerSizes.sizes.medium / controllerSizes.sizes.large) * 35}px);
      }
      @media ${device.tabletL} {
        bottom: calc(50% + 35px);
      }
    `}
  ${({$bottom}) =>
    $bottom &&
    css`
      top: calc(50% + ${(controllerSizes.sizes.small / controllerSizes.sizes.large) * 30}px);
      @media ${device.tabletM} {
        top: calc(50% + ${(controllerSizes.sizes.medium / controllerSizes.sizes.large) * 30}px);
      }
      @media ${device.tabletL} {
        top: calc(50% + 30px);
      }
    `}
  width: 85%;
  transform: translateX(-50%);
  color: ${colors.white};
  opacity: ${({visible}) => (visible ? 1 : 0)};
  text-align: center;
  transition: all ${transitions.controllerPlay}ms;
`

const H2 = styled.h2`
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: ${(controllerSizes.sizes.small / controllerSizes.sizes.large) * 2}px;
  font-size: ${(controllerSizes.sizes.small / controllerSizes.sizes.large) * 1}rem;
  @media ${device.tabletM} {
    padding: ${(controllerSizes.sizes.medium / controllerSizes.sizes.large) * 2}px;
    font-size: ${(controllerSizes.sizes.medium / controllerSizes.sizes.large) * 1}rem;
  }
  @media ${device.tabletL} {
    padding: 2px;
    font-size: 1rem;
  }
`

const H3 = styled.h3`
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.5);
  font-weight: 400;

  padding: ${(controllerSizes.sizes.small / controllerSizes.sizes.large) * 2}px;
  font-size: ${(controllerSizes.sizes.small / controllerSizes.sizes.large) * 1}rem;
  @media ${device.tabletM} {
    padding: ${(controllerSizes.sizes.medium / controllerSizes.sizes.large) * 2}px;
    font-size: ${(controllerSizes.sizes.medium / controllerSizes.sizes.large) * 1}rem;
  }
  @media ${device.tabletL} {
    padding: 2px;
    font-size: 1rem;
  }
`

const BarsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`

const OuterCircle = styled.div`
  opacity: ${({visible}) => (visible ? 1 : 0)};
  visibility: ${({visible}) => (visible ? "visible" : "hidden")};
  border-radius: 50%;
  padding: 10%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all ${transitions.controllerPlay}ms;
`
const InnerCircle = styled.div`
  border: 5px solid ${colors.white};
  border-width: ${(controllerSizes.sizes.small / controllerSizes.sizes.large) * 5}px;
  @media ${device.tabletM} {
    border-width: ${(controllerSizes.sizes.medium / controllerSizes.sizes.large) * 5}px;
  }
  @media ${device.tabletL} {
    border-width: 5px;
  }
  border-radius: 50%;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  transform: translateZ(
    0
  ); // https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.3s all;
  &:hover {
    background-color: rgba(0, 0, 0, 0.6);
  }
`

const ArrowContainer = styled.div`
  cursor: pointer;
  transition: all ${transitions.controllerPlay}ms;
  width: 28%;
  height: 28%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  svg {
    fill: ${colors.white};
  }
`

export default ControllerPlay
