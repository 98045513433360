// 3rd party
import React, {useEffect} from "react"
import styled, {css} from "styled-components"
import {useSelector, useDispatch} from "react-redux"

// constants, actions
import sizes, {device} from "../../constants/css"
import {setIsControllerMinimized} from "../../actions/index"

function PageWrapper({children, isHome, ...props}) {
  const dispatch = useDispatch()
  const isControllerMinimized = useSelector(state => state.isControllerMinimized)
  const isMenuOpen = useSelector(state => state.isMenuOpen)
  useEffect(() => {
    dispatch(setIsControllerMinimized(!isHome))
  }, [children, isHome, dispatch])

  return (
    <PageOuter {...props} {...{isHome}}>
      <Container hiddenByMenu={isMenuOpen} hiddenByPlayer={!isControllerMinimized && !isHome}>
        {children}
      </Container>
    </PageOuter>
  )
}

const PageOuter = styled.div`
  grid-area: main;
  height: 100%;
  @media ${device.tabletL} {
    margin: 0 0 0 auto;
    width: ${sizes.pageWidth}px;
  }
  ${({isHome}) =>
    isHome &&
    css`
      display: none;
      visibility: hidden;
    `}//overflow: hidden;
`

const Container = styled.div`
  height: 100%;
  transition: all 0.35s;
  text-align: right;
  position: relative;
  ${({hiddenByMenu, hiddenByPlayer}) =>
    (hiddenByMenu || hiddenByPlayer) &&
    css`
      filter: blur(5px);
      opacity: 0.7;
      pointer-events: none;
      cursor: none;
    `};
`

export default PageWrapper
