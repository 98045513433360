// 3rd party
import React from "react"
import styled from "styled-components"
import {useSelector, useDispatch} from "react-redux"

// components
import BurgerCircle from "./burgerCircle"

// actions
import {toggleMenu} from "../../actions/index"

function Hamburger({children}) {
  const isMenuOpen = useSelector(state => state.isMenuOpen)
  const dispatch = useDispatch()
  return (
    <CircleWrapper>
      <div></div>
      <CircleWrapperInner>
        <BurgerCircle open={isMenuOpen} />
        <InnerCircleWrapper>
          <InnerCircle>
            <StyledBurger open={isMenuOpen} onClick={() => dispatch(toggleMenu())} aria-label="Toggle menu">
              <div />
              <div />
              <div />
            </StyledBurger>
          </InnerCircle>
        </InnerCircleWrapper>
        {children}
      </CircleWrapperInner>
    </CircleWrapper>
  )
}

const InnerCircleWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 13px;
`

const InnerCircle = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

const CircleWrapper = styled.div`
  position: relative;
  height: 100%;
  z-index: 1001;
`
const CircleWrapperInner = styled.div`
  position: relative;
`

const StyledBurger = styled.button`
  transition: all 0.1s;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 54px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none;
  }

  div {
    width: 100%;
    height: 8px;
    background: black;
    border-radius: 10px;
    transition: ${({open}) => (open ? "transform .2s ease-in, top .1s .2s;" : "transform .2s .1s, top 0.1s;")};
    position: relative;
    transform-origin: 1px;

    // burger lines
    :first-child {
      position: absolute;
      top: 12px;
      transform: ${({open}) => (open ? "rotateZ(90deg)" : "")};
      transform-origin: center ${9 + 6}px;
    }

    :nth-child(2) {
      position: absolute;
      top: ${({open}) => (open ? "calc(50% + 4px)" : "calc(50% - 4px)")};
      transform-origin: 50% 50%;
      transform: ${({open}) => (open ? "rotateZ(90deg)" : "")};
    }

    :nth-child(3) {
      position: absolute;
      bottom: 12px;
      transform: ${({open}) => (open ? "rotateZ(90deg)" : "")};
      transform-origin: center -${9 - 2}px;
    }
  }
`

export default Hamburger
