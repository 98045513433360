export const moveVertically = delta => {
  return {
    type: "MOVE_VERTICALLY",
    payload: delta
  }
}

export const moveHorizontally = (delta, playlist) => {
  return {
    type: "MOVE_HORIZONTALLY",
    payload: delta,
    playlist: playlist
  }
}

export const initializeTracks = value => {
  return {
    type: "TRACKS_INITIALIZE",
    value: value
  }
}

export const setPlaylists = value => {
  return {
    type: "PLAYLISTS",
    value: value
  }
}

export const setPlaylistCount = value => {
  return {
    type: "PLAYLIST_SET_COUNT",
    value: value
  }
}

export const toggleMenu = () => {
  return {
    type: "MENU_TOGGLE"
  }
}

export const setIsMenuOpen = open => {
  return {
    type: "MENU_SET_OPEN",
    payload: open
  }
}

export const setIsControllerMinimized = value => {
  return {
    type: "CONTROLLER_SET_MINIMIZED",
    payload: value
  }
}

export const setVolume = value => {
  return {
    type: "PLAYER_SET_VOLUME",
    value: value
  }
}

export const setProgress = value => {
  return {
    type: "PLAYER_SET_PROGRESS",
    payload: value
  }
}

export const setCurrentTrackIds = value => {
  return {
    type: "PLAYER_SET_CURRENT_TRACK",
    value: value
  }
}

export const setIsPlaying = value => {
  return {
    type: "PLAYER_SET_IS_PLAYING",
    value: value
  }
}

export const seekPlayer = value => {
  return {
    type: "PLAYER_SEEK",
    value: value
  }
}

export const setIsLoading = value => {
  return {
    type: "WIDGET_SET_IS_LOADING",
    value: value
  }
}

export const handleChangeTrack = (playlists, payload = 1) => {
  return {
    type: "PLAYER_HANDLE_CHANGE_TRACK",
    playlists: playlists,
    payload: payload
  }
}

export const setIsHome = value => {
  return {
    type: "PAGE_SET_IS_HOME",
    value: value
  }
}

export const setModalImage = value => {
  return {
    type: "MODAL_SET_IMAGE",
    value: value
  }
}

export const setModalIsOpen = value => {
  return {
    type: "MODAL_SET_IS_OPEN",
    value: value
  }
}
