const menuReducer = (state = false, action) => {
  switch (action.type) {
    case "MENU_TOGGLE":
      return !state
    case "MENU_SET_OPEN":
      return action.payload
    default:
      return state
  }
}
export default menuReducer
