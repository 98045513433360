// 3rd party
import React, {useState} from "react"
import styled from "styled-components"
import {useSelector, useDispatch} from "react-redux"

// components
import ControllerLi from "./styled/controllerLi"
import TriangleButton from "./triangleButton"
import ControllerItemInner from "./controllerItemInner"

// constants
import {controllerSizes, transitions} from "../../constants/css"
import {moveVertically, moveHorizontally} from "../../actions/index"
import {correctValue} from "../../utils/helpers"

const ControllerItem = ({level, row = 0, trackIndex, albumCover, background, placeholder, isControllerCollapsed}) => {
  // selectors, dispatch
  const highlightedPlaylist = useSelector(state => state.highlightedPlaylist)
  const playlistCount = useSelector(state => state.playlistCount)
  let dispatch = useDispatch()

  // data
  let isItemCollapsed = (isControllerCollapsed && !placeholder) || (row !== 0 && level !== 0)
  let zIndex = !background ? 6 - Math.abs(level) : -1
  let {sizes, translates, ratios, exitTranslates, exitRatio} = controllerSizes
  let translate = !isItemCollapsed ? translates[-level + 2] : 0
  let exitTranslate = exitTranslates[-level + 2]
  let ratio = ratios[-level + 2]
  let [isActive, setIsActive] = useState(false)
  let isClickable = !(row === 0 && level === 0)

  // functions
  function handleClick() {
    if (!isClickable) {
      return
    }
    if (row === 0) {
      dispatch(moveHorizontally(0 - level, correctValue(highlightedPlaylist, playlistCount)))
    } else {
      dispatch(moveVertically(0 - row))
    }
  }
  return (
    <LiHorizontal
      id={"item-" + trackIndex}
      {...{sizes, translate, isClickable, ratio, exitRatio, exitTranslate, zIndex}}
      className={"level-" + level}
      visible={true}
      onClick={() => handleClick()}
      onMouseOver={() => setIsActive(true)}
      onMouseOut={() => setIsActive(false)}
    >
      {!placeholder && <ControllerItemInner visible={level === 0 && row === 0} {...{albumCover}} />}
      <TriangleButton {...{level, row, placeholder, isItemCollapsed, isControllerCollapsed, isActive}} />
    </LiHorizontal>
  )
}
const LiHorizontal = styled(ControllerLi)`
  transform: ${({ratio, translate}) => `translateX(-50%) translateX(${translate}%) scale(${ratio})`};
  transition: all ${transitions.controllerItem}ms ease-out;
  cursor: ${({isClickable}) => (isClickable ? "pointer" : "default")};

  // transition styles
  &.right-enter,
  &.left-enter,
  &.right-exit,
  &.left-exit {
    transition: all 0s;
  }
  &.right-enter {
    opacity: 0;
    transform: ${({ratio, translate, exitTranslate, exitRatio}) =>
      `translateX(-50%) translateX(${translate + exitTranslate}%) scale(${ratio * exitRatio})`};
  }

  &.left-enter {
    opacity: 0;
    transform: ${({ratio, translate, exitTranslate, exitRatio}) =>
      `translateX(-50%) translateX(${translate - exitTranslate}%) scale(${ratio * exitRatio})`};
  }

  &.right-exit {
    opacity: 1;
    transform: ${({ratio, translate}) => `translateX(-50%) translateX(${translate}%) scale(${ratio})`};
  }

  &.left-exit {
    opacity: 1;
    transform: ${({ratio, translate}) => `translateX(-50%) translateX(${translate}%)  scale(${ratio})`};
  }

  &.right-enter-active,
  &.left-enter-active,
  &.right-exit-active,
  &.left-exit-active,
  &.right-enter-done,
  &.left-enter-done {
    transition: all ${transitions.controllerItem}ms ease-out;
  }
  &.right-enter-active {
    opacity: 1;
    transform: ${({ratio, translate}) => `translateX(-50%) translateX(${translate}%) scale(${ratio})`};
  }
  &.left-enter-active {
    opacity: 1;
    transition: all ${transitions.controllerItem}ms ease-out;
    transform: ${({ratio, translate}) => `translateX(-50%) translateX(${translate}%) scale(${ratio})`};
  }

  &.right-exit-active {
    opacity: 0;
    transform: ${({ratio, translate, exitTranslate, exitRatio}) =>
      `translateX(-50%) translateX(${translate + exitTranslate}%) scale(${ratio * exitRatio})`};
  }

  &.left-exit-active {
    opacity: 0;
    transform: ${({ratio, translate, exitTranslate, exitRatio}) =>
      `translateX(-50%) translateX(${translate - exitTranslate}%) scale(${ratio * exitRatio})`};
  }
`

export default React.memo(ControllerItem)
