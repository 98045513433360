// 3rd party
import React from "react"
import styled from "styled-components"

// components
import MainTwoCol from "../styled/mainTwoCol"
import Arrow from "../icons/arrow"
import Socials from "../sections/socials"
import AnimatingLink from "../general/animatingLink"

// constants/utils
import {colors} from "../../constants/css"
import {getDdMmYyyyFrom, getHhMmFrom} from "../../utils/helpers"
import Editor from "../sections/editor"
import {getStartDate} from "../../utils/dataManipulation"
import {graphql, useStaticQuery} from "gatsby"

function ShowsList({shows}) {
  const {settings} = useStaticQuery(query)
  return (
    <Ul>
      {shows.map((show, i) => {
        show = show.node ? show.node : show
        let startDate = getStartDate(show)
        let startTime = getHhMmFrom(startDate)
        return (
          <li key={i}>
            <MainTwoCol>
              <div>
                <h2>{show.title}</h2>
                <Content>
                  <Editor content={show.info.content} />
                </Content>
                {!!show.info.artists?.length && (
                  <>
                    <ul>
                      {show.info.artists.map((artist, i) => {
                        return (
                          <li key={i}>
                            <AnimatingLink to={artist.uri} key={artist.uri} aria-label={artist.name}>
                              <b>{artist.name}</b>
                            </AnimatingLink>
                          </li>
                        )
                      })}
                    </ul>
                  </>
                )}
              </div>
              <div>
                <DateBar large={true}>
                  <Arrow color="white" /> {getDdMmYyyyFrom(startDate)}
                </DateBar>
                <p>{startTime}</p>
                {show.info.soldOut && <DateBar large={false}>{settings.other.all.soldOutLabel}</DateBar>}
                <Socials facebook={show.info.eventUrl} />
              </div>
            </MainTwoCol>
          </li>
        )
      })}
    </Ul>
  )
}
const DateBar = styled.p`
  transform: translateY(${({large}) => (large ? 4 : -8)}px);
  color: ${colors.white};
  background-color: ${colors.black};
  padding: 2px 1px;
  font-size: 0.6875rem;
  svg {
    height: 8px;
  }
  text-align: left;
  margin-bottom: ${({large}) => large && "16px"};
`
const Content = styled.div`
  p {
    text-align: right;
  }
`
const Ul = styled.ul`
  display: grid;
  grid-gap: 25px;
`

const query = graphql`
  query SoldOutLabelQuery {
    settings: wp {
      other: otherSettings {
        all: otherSettings {
          soldOutLabel
        }
      }
    }
  }
`

export default ShowsList
