// 3rd party
import React from "react"
import styled from "styled-components"
import {useSelector, useDispatch} from "react-redux"

// components
import Arrow from "../icons/arrow"
import Pause from "../icons/pause"

// actions
import {setIsPlaying} from "../../actions/index"

function PlayPause(/*{isLoading}*/) {
  const isPlaying = useSelector(state => state.playerIsPlaying)
  const dispatch = useDispatch()

  function clickHandler() {
    /*if (!isLoading) {*/
    !isPlaying ? dispatch(setIsPlaying(true)) : dispatch(setIsPlaying(false))
    /*}*/
  }
  return (
    <StyledButton onClick={() => clickHandler()} aria-label="Toggle play">
      {isPlaying && <Pause />}
      {!isPlaying && <Arrow />}
    </StyledButton>
  )
}

const StyledButton = styled.button`
  padding: 3px;
  cursor: pointer;
`

export default PlayPause
