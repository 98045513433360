import React from "react"
import styled from "styled-components"
import {transitions} from "../../constants/css"

function Ornament() {
  return (
    <Svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 195 195"
      style={{enableBackground: "new 0 0 195 195"}}
    >
      <path
        className="st0"
        d="M136.9,78.8c-12.4,0-23.9,3.9-33.4,10.6L74.5,60.3C78,54.4,80,47.4,80,40C80,17.9,62.1,0,40,0S0,17.9,0,40
	s17.9,40,40,40c7.4,0,14.4-2,20.3-5.5l29.1,29.1c-6.6,9.4-10.6,20.9-10.6,33.4c0,32.1,26,58.1,58.1,58.1s58.1-26,58.1-58.1
	C195,104.8,169,78.8,136.9,78.8z"
      />
    </Svg>
  )
}

const Svg = styled.svg`
  @keyframes rotate-ornament {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(0.75);
    }
    75% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  animation: rotate-ornament ${transitions.ornaments / 2}ms ease 1;
  animation-delay: ${transitions.ornaments / 2}ms;
`

export default Ornament
