import React from "react"
import Logo from "./nav/logo"
import {device, sizes} from "../constants/css"
import styled from "styled-components"
import NavOpener from "./nav/navOpener"
import Announcer from "./announcer"

function Header({isHome}) {
  return (
    <HeaderOuter className="headerOuter">
      <Announcer {...{isHome}} />
      <HeaderGrid>
        <NavOpener />
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
      </HeaderGrid>
    </HeaderOuter>
  )
}

const HeaderOuter = styled.div`
  grid-area: header;
`
const HeaderGrid = styled.div`
  grid-template-areas: "logo hamburger";
  display: grid;
  grid-template-columns: ${sizes.header.height}px ${sizes.header.height}px;
  justify-content: space-between;

  @media ${device.laptop} {
    margin: 0 0 0 auto;
    justify-content: end;
    grid-template-areas: "hamburger logo";
    grid-column-gap: ${sizes.header.spacing}px;
  }
`

const LogoWrapper = styled.div`
  grid-area: logo;
`

export default React.memo(Header)
