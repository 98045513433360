import React, {useState, useEffect} from "react"
import styled from "styled-components"
import {colors, device} from "../../constants/css"

let bars = [
  {$duration: 500, $scale: 1.1},
  {$duration: 1000, $scale: 0.91},
  {$duration: 400, $scale: 0.83},
  {$duration: 600, $scale: 0.76},
  {$duration: 1000, $scale: 0.7},
  {$duration: 400, $scale: 0.65},
  {$duration: 700, $scale: 0.61},
  {$duration: 800, $scale: 0.58},
  {$duration: 600, $scale: 0.56},
  {$duration: 300, $scale: 0.55},
  {$duration: 700, $scale: 0.54},
  {$duration: 300, $scale: 0.55},
  {$duration: 500, $scale: 0.56},
  {$duration: 1000, $scale: 0.58},
  {$duration: 700, $scale: 0.61},
  {$duration: 400, $scale: 0.65},
  {$duration: 1100, $scale: 0.7},
  {$duration: 400, $scale: 0.76},
  {$duration: 600, $scale: 0.83},
  {$duration: 1000, $scale: 0.91},
  {$duration: 500, $scale: 1}
]

function AnimateBars({width, height, nBars, animate}) {
  let [isLoaded, setIsLoaded] = useState(false)
  useEffect(() => setIsLoaded(true), [])
  return (
    <Bars {...{width, height, nBars}}>
      {bars.map(({$duration, $scale}, i) => {
        return <Bar key={i} {...{$duration, $scale, animate, isLoaded}} />
      })}
    </Bars>
  )
}

const Bars = styled.div`
  @keyframes animate-bar {
    0% {
      height: ${({height}) => height}px;
    }
    100% {
      height: 15px;
    }
  }

  margin: 0 auto;
  visibility: hidden;
  display: none;
  @media ${device.tabletL} {
    visibility: visible;
    display: flex;
  }
  align-items: start;
  justify-content: center;
  margin: 0 auto;

  transform: scaleY(-1);
  width: ${({width}) => width}px;
  height: ${({height}) => height}px;
`

const Bar = styled.div`
  width: 9px;
  bottom: 0;
  height: 100%;
  margin: 0 1px;

  background-color: ${colors.white};
  transform-origin: top;
  transition: 1000ms;
  transform: scaleY(${({$scale, isLoaded}) => (isLoaded ? $scale : 0)});
  animation: animate-bar 600ms linear infinite alternate;
  animation-duration: ${({$duration}) => $duration}ms;
  animation-play-state: ${({animate}) => (animate ? "playing" : "paused")};
`

export default AnimateBars
