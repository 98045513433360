// 3rd party
import React, {useState, useEffect} from "react"
import styled from "styled-components"
import {useSelector} from "react-redux"
// components
import Volume from "./volume"
import PlayerWave from "./playerWave"
import AudioPlayer from "./audioPlayer.js"
import CurrentTrackImage from "./currentTrackImage"
import CurrentTrackDetails from "./currentTrackDetails"
//import FadeIn from "../fadeIn"
import PlayerButtons from "./playerButtons"

// constants
import {device, sizes, transitions} from "../../constants/css.js"

function PlayerArea() {
  const currentTrackIds = useSelector(state => state.playerCurrentTrackIds)
  const [isCollapsed, setIsCollapsed] = useState(true)
  useEffect(() => {
    //setTimeout(() => setIsCollapsed(true), transitions.ornaments)
    if (isCollapsed) {
      if (currentTrackIds.playlistId >= 0 && currentTrackIds.trackId >= 0) {
        setIsCollapsed(false)
      }
    }
  }, [isCollapsed, currentTrackIds])
  return (
    <>
      {/*<FadeIn delay={500}>*/}
      <Container {...{isCollapsed}}>
        <StyledPlayer>
          <StyledPlayerGrid>
            <PlayerButtons />
            <PlayerWave />
            <CurrentTrackImage />
            <Volume />
            <CurrentTrackDetails />
          </StyledPlayerGrid>
        </StyledPlayer>
      </Container>
      <AudioPlayer />
      {/*</FadeIn>*/}
    </>
  )
}
const Container = styled.div`
  transition: ${transitions.ornaments}ms;
  transform: translateY(${({isCollapsed}) => (!isCollapsed ? 0 : 100)}%);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  backdrop-filter: blur(10px);
  @media ${device.laptop} {
    width: ${sizes.container}px;
    margin: 0 auto;
  }
`

const StyledPlayer = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  padding: ${sizes.playerPadding}px 5px;
`

const StyledPlayerGrid = styled.div`
  transition: all 1.4s;
  transform: ${({isCollapsed}) => (isCollapsed ? "translateY(00%)" : "")};
  align-items: center;
  display: grid;
  grid-auto-flow: row;
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  grid-template-areas: "main main main main " "pic vol name buttons  ";
  grid-template-rows: auto auto;
  grid-template-columns: min-content min-content minmax(0, 1fr) min-content;
  @media ${device.tabletM} {
    grid-row-gap: 0px;
    grid-auto-columns: min-content;
    justify-content: center;
    grid-template-areas: "buttons main pic vol name";
    grid-template-columns: auto 1fr ${sizes.waveHeight}px auto 150px;
  }
`
export default React.memo(PlayerArea)
