// 3rd party
import React from "react"
import {useStaticQuery, graphql} from "gatsby"
import ShowsList from "../lists/showsList"
import {getFutureShows} from "../../utils/dataManipulation"

function ShowsArchive() {
  let {shows} = useStaticQuery(query)
  let futureShows = getFutureShows(shows.edges)
  return <ShowsList shows={futureShows} />
}

const query = graphql`
  query allShows {
    shows: allWpShow {
      edges {
        node {
          id
          title
          info: showInfo {
            content
            artists: showArtists {
              ... on WpArtist {
                id
                uri
                name: title
              }
            }
            eventStart
            eventUrl
            soldOut
          }
        }
      }
    }
  }
`

export default ShowsArchive
