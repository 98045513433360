import React from "react"
import styled from "styled-components"
import TrackReference from "../trackReference"

function TracksList({tracks}) {
  return (
    <ul>
      {tracks.map((track, i) => {
        return (
          <Li key={i}>
            <TrackReference {...{track}} />
          </Li>
        )
      })}
    </ul>
  )
}

const Li = styled.li`
  svg {
    height: 10px;
  }
`

export default TracksList
