// 3rd party
import React from "react"
import styled from "styled-components"
import {useSelector} from "react-redux"

// constants
import {device, sizes} from "../../constants/css.js"
import {getCurrentTrack} from "../../utils/dataManipulation.js"

// components
import AnimatingLink from "../general/animatingLink.js"

function CurrentTrackDetails() {
  const playlists = useSelector(state => state.playlists)
  const currentTrackIds = useSelector(state => state.playerCurrentTrackIds)
  let currentTrack = getCurrentTrack(playlists, currentTrackIds)
  let info = currentTrack?.info
  return currentTrack ? (
    <SongName>
      {info.artist?.length && (
        <AnimatingLink to={info.artist[0].uri} aria-label={info.artist[0].name}>
          <NoWrap>
            <b>{info.artist[0].name}</b>
          </NoWrap>
        </AnimatingLink>
      )}
      <NoWrap>{currentTrack.title}</NoWrap>
    </SongName>
  ) : null
}
const NoWrap = styled.div`
  white-space: nowrap;
  overflow: hidden;
`

const SongName = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.tabletM} {
    display: block;
    width: 150px;
    overflow: hidden;
    max-height: ${sizes.waveHeight}px;
  }
  font-size: 0.75rem;
  grid-area: name;
`

export default CurrentTrackDetails
