// 3rd party
import React from "react"
import styled from "styled-components"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import {useSelector /*, useDispatch*/} from "react-redux"

// constants, actions
import {colors, sizes} from "../../constants/css.js"
//import {setIsPlaying} from "../../actions/index"
import {getCurrentTrack} from "../../utils/dataManipulation.js"

function CurrentTrackImage({backgroundColor = colors.black}) {
  const playlists = useSelector(state => state.playlists)
  const currentTrackIds = useSelector(state => state.playerCurrentTrackIds)
  //const dispatch = useDispatch()
  let currentTrack = getCurrentTrack(playlists, currentTrackIds)
  const image = getImage(currentTrack?.featuredImage?.node.localFile)

  return currentTrack ? (
    <>
      {
        /*<a
      href={currentTrack?.info?.url}
      rel="noopener noreferrer"
      target="_blank"
      aria-label="Visit track on Soundcloud"
      onClick={() => dispatch(setIsPlaying(false))}
    >
      {*/
        image && (
          <StyledImg
            $backgroundColor={backgroundColor}
            alt={currentTrack.title}
            image={image}
            //width={sizes.waveHeight}
            //height={sizes.waveHeight}
          />
        )
      }
      {/*</a>*/}
    </>
  ) : null
}

const StyledImg = styled(GatsbyImage)`
  background-color: ${({$backgroundColor}) => $backgroundColor};
  grid-area: pic;
  width: ${sizes.waveHeight}px;
  height: ${sizes.waveHeight}px;
`

export default CurrentTrackImage
