const controllerReducer = (state = false, action) => {
  switch (action.type) {
    case "CONTROLLER_TOGGLE":
      return !state
    case "CONTROLLER_SET_MINIMIZED":
      return action.payload
    default:
      return state
  }
}
export default controllerReducer
