// 3rd party
import React from "react"
import styled, {css} from "styled-components"
import {GatsbyImage, getImage} from "gatsby-plugin-image"

// components
import ExternalLink from "../general/externalLink"
import TrackReference from "../trackReference"
import Arrow from "../icons/arrow"
import AnimatingLink from "../general/animatingLink"

// constants
import {colors} from "../../constants/css"

function ProductsList({products, isSmall = false}) {
  return (
    <Ul isSmall={isSmall} number={products.length}>
      {products.map((product, i) => {
        product = product.node ? product.node : product
        const image = getImage(product.featuredImage?.node.localFile)
        let priceInt = Math.floor(product.info.price).toString()
        let priceDec = Math.round((product.info.price - priceInt) * 100).toString()
        return (
          <React.Fragment key={i}>
            {/*isLast && isOdd && !isSmall && <Li isDummy={true} />*/}
            {/*isLast && isRest1 && isSmall && (
              <>
                <Li isDummy={true} />
                <Li isDummy={true} />
              </>
            )*/}
            <Li>
              <ImageWrapper>
                {image && <GatsbyImage image={image} alt={product.title} />}
                {!!product.info.track?.length && (
                  <PlayWrapper isSmall={isSmall}>
                    <TrackReference track={product.info.track[0]} hasTitle={false} hasCircle={true} />
                  </PlayWrapper>
                )}
                <ArrowWrapper>
                  <Arrow />
                </ArrowWrapper>
              </ImageWrapper>

              <Bottom>
                {!isSmall && !!product.info?.artist?.length && (
                  <H3 isSmall={isSmall} bold>
                    <AnimatingLink to={product.info.artist[0].uri}>{product.info.artist[0].name}</AnimatingLink>
                  </H3>
                )}
                <H3 isSmall={isSmall}>{product.title}</H3>
                <ExternalLink url={product.info.productUrl} title={`Buy ${product.title}`}>
                  <Span>
                    {priceInt}
                    {priceDec !== "0" && ","}
                    {priceDec !== "0" && <sup>{priceDec}</sup>} &euro;
                  </Span>
                </ExternalLink>
              </Bottom>
            </Li>
          </React.Fragment>
        )
      })}
    </Ul>
  )
}
const ArrowWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform-origin: left bottom;
  transform: translateX(50%) rotate(-90deg);
`
const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  img {
    height: 100%;
  }
`
const PlayWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  svg {
    width: ${({isSmall}) => (isSmall ? 25 : 35)}px;
    fill: ${colors.white};
  }
`
const Bottom = styled.div`
  padding: 4px 7px;
  a {
    &:hover {
      color: ${colors.white};
    }
  }
`
const H3 = styled.h3`
  font-size: ${({isSmall}) => (isSmall ? 0.625 : 0.875)}rem;
  margin: 0;
  ${({bold}) =>
    bold &&
    css`
      font-weight: 700;
    `}
`
const Span = styled.span`
  font-size: ${({isSmall}) => (isSmall ? 0.75 : 1)}rem;
`
const Li = styled.li`
  background-color: ${({isDummy}) => (isDummy ? "transparent" : colors.black)};
  color: ${colors.white};
  text-align: left;
  a {
    color: ${colors.white};
  }
  overflow: hidden;
  border-radius: 0 0 10px 10px;
  //width: 80px;
`

const Ul = styled.ul`
  display: grid;
  list-style: none;
  //grid-template-columns: ${({isSmall}) => (isSmall ? "repeat(3, 80px)" : "repeat(2, 1fr)")};
  grid-template-columns: repeat(${({isSmall}) => (isSmall ? 3 : 2)}, ${({isSmall}) => (isSmall ? "80px" : "187px")});
  grid-template-rows: repeat(${({number, isSmall}) => Math.ceil(number / (isSmall ? 3 : 2))}, 1fr);
  grid-column-gap: 25px;
  //grid-auto-flow: column;
  grid-row-gap: 35px;
  direction: rtl;
  justify-content: start;
  //justify-content: space-between;
  width: 100%;
`

export default ProductsList
