// 3rd party
import React, {useEffect, useCallback, useRef, useState} from "react"
import styled from "styled-components"
import {useDispatch, useSelector} from "react-redux"
// import ReactPlayer from "react-player/soundcloud"

// context, actions
import {setProgress, setIsLoading, setIsPlaying, handleChangeTrack} from "../../actions/index"
import {getCurrentTrack} from "../../utils/dataManipulation"

const isAudioPlaying = audio => !!(audio?.currentTime > 0 && !audio?.paused && !audio?.ended && audio?.readyState > 2)

function AudioPlayer() {
  // selectors
  const isPlaying = useSelector(state => state.playerIsPlaying)
  const seekProgress = useSelector(state => state.playerSeekProgress)
  const currentTrackIds = useSelector(state => state.playerCurrentTrackIds)
  const volume = useSelector(state => state.playerVolume)
  const isWidgetLoading = useSelector(state => state.isWidgetLoading)
  const playlists = useSelector(state => state.playlists)
  let currentTrack = getCurrentTrack(playlists, currentTrackIds)
  let [soundPromise, setSoundPromise] = useState(false)

  // dispatch, ref
  const dispatch = useDispatch()
  const audioRef = useRef()

  // effects
  useEffect(() => {
    if (audioRef.current?.duration) {
      dispatch(setIsLoading(true))
      audioRef.current.currentTime = seekProgress * audioRef.current.duration || 0
    }
  }, [seekProgress, audioRef])

  useEffect(() => (audioRef.current.volume = parseFloat(volume / 100)), [volume, audioRef])

  useEffect(() => {
    if (audioRef.current && audioRef.current?.src) {
      if (isPlaying) {
        // want to play
        setSoundPromise(audioRef.current.play())
        dispatch(setIsLoading(true))
      } else {
        // want to pause
        if (soundPromise /* || isAudioPlaying(audioRef.current)*/) {
          // check if audio is actually playing to prevent error
          // https://stackoverflow.com/questions/6877403/how-to-tell-if-a-video-element-is-currently-playing/6877530#6877530
          soundPromise.then(function (_) {
            //Pause and reset the sound
            audioRef.current.pause()
            setSoundPromise(undefined)
            //audioRef.current.currentTime = 0
          })
          //audioRef.current.pause()
          if (isWidgetLoading) dispatch(setIsLoading(false))
        } else {
          // pausing failed, so set is playing again
          // console.log("-> pausing failed, so set is playing again")
          dispatch(setIsPlaying(true))
        }
      }
    }
  }, [isPlaying, currentTrack, audioRef, dispatch])

  //useEffect(() => dispatch(setIsLoading(true)), [currentTrackIds, dispatch])

  // callbacks
  const keyHandler = useCallback(
    event => {
      if (!isWidgetLoading) {
        switch (event.keyCode) {
          case 80:
            // console.log("play pause")
            return
          case 32:
            dispatch(setIsPlaying(!isPlaying))
            return
          default:
            return
        }
      }
    },
    [isWidgetLoading, isPlaying, dispatch]
  )

  useEffect(() => {
    document.addEventListener("keydown", keyHandler, false)
    return () => document.removeEventListener("keydown", keyHandler, false)
  }, [keyHandler])
  return (
    <>
      <Visible>
        <audio
          ref={audioRef}
          onTimeUpdate={e => {
            let progress = audioRef.current.currentTime / audioRef.current.duration
            dispatch(setProgress(progress))
            if (isWidgetLoading && audioRef.current.currentTime) dispatch(setIsLoading(false))
          }}
          onCanPlayThrough={e => {
            //console.log(" canplaythrough")
            dispatch(setIsLoading(false))
          }}
          //onLoadedMetadata={e => console.log(audioRef.current.duration)}
          onEnded={() => dispatch(handleChangeTrack(playlists))}
          controls
          src={currentTrack?.info?.track?.localFile?.publicURL}
          //onReady={() => dispatch(setIsLoading(false))}
          //onPause={() => dispatch(setIsPlaying(false))}
        />
      </Visible>
    </>
  )
}

const Visible = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  overflow: hidden;
  transform: translateY(150%);
  display: none;
  visibility: hidden;
`

export default AudioPlayer
