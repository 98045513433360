import React from "react"
import Seo from "../components/general/seo"

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />

    <h1>NOT FOUND</h1>
    <p>This page doesn't exist.</p>
  </>
)

export default NotFoundPage
