//import React, {useState, useEffect, useRef} from "react"
import {sizes} from "../constants/css"

export function getDdMmYyyyFrom(date) {
  return date
    ? date.getDate().toString().padStart(2, "0") +
        " " +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        " " +
        date.getFullYear()
    : ""
}

export function getHhMmFrom(date) {
  return date ? date.getHours() + ":" + date.getMinutes().toString().padStart(2, "0") : ""
}

export function getWidgetUrl(id, needsPrefix = true) {
  let baseUrl = needsPrefix
    ? "https://w.soundcloud.com/player/?url=https://api.soundcloud.com/playlists/"
    : "https://api.soundcloud.com/playlists/"
  let params = {
    ///auto_play tf
    //color hex
    buying: false,
    sharing: false,
    download: false,
    show_artwork: false,
    show_playcount: false,
    show_user: false,
    auto_play: false
  }
  let paramString = ""
  for (var option in params) {
    if (params.hasOwnProperty(option)) {
      paramString += "&" + option + "=" + params[option]
    }
  }
  let widgetUrl = baseUrl + id + paramString
  return widgetUrl
}

export function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

const normalizeData = filteredData => {
  const multiplier = Math.pow(Math.max(...filteredData), -1)
  return filteredData.map(n => n * multiplier)
}

export function resampleData(samples, samplesCount) {
  const blockSize = samples.length / samplesCount //Math.floor(samples.length / samplesCount)
  const filteredData = []
  for (let i = 0; i < samplesCount; i++) {
    //let startIndex = ((i - 1) * width) / samplesCount
    //let endIndex = (i * width) / samplesCount

    // average over samples
    //let nums = samples.slice(Math.round(startIndex), Math.round(endIndex))
    //let avg = nums.reduce((a, b) => a + b) / nums.length
    //resampled.push(avg)
    //resampled.push(samples[Math.round(startIndex)])
    let blockStart = blockSize * i // the location of the first sample in the block
    let sum = 0
    for (let j = 0; j < blockSize; j++) {
      sum = sum + Math.abs(samples[Math.floor(blockStart) + j]) // find the sum of all the samples in the block
    }

    filteredData.push(sum / blockSize) // divide the sum by the block size to get the average
  }
  return filteredData
}

export function resampleAndNormalizeWave(samples, containerWidth) {
  // https://css-tricks.com/making-an-audio-waveform-visualizer-with-vanilla-javascript/
  if (!samples?.length || !containerWidth) {
    return null
  }
  const samplesCount = Math.round(containerWidth / (sizes.barWidth + sizes.barSpacing))
  let resampled = resampleData(samples, samplesCount)
  resampled = normalizeData(resampled)
  return resampled
}

export function correctValue(value, length) {
  // console.log(value, length)
  if (value < 0) {
    value = length - (-value % length)
  } else if (value >= length) {
    value = value % length
  }
  // value may now be equal to array length, which is out of bounds... should be 0
  value = value === length ? 0 : value
  return value
}

export default resampleAndNormalizeWave
