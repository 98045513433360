import styled from "styled-components"

const Loader = styled.div`
  @keyframes lds-circle {
    0%,
    100% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(1800deg);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
      transform: rotateY(3600deg);
    }
  }

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }
    20% {
      transform: scale(1.2);
    }
    40% {
      transform: scale(1);
    }
    60% {
      transform: scale(1.2);
    }
    80% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
  width: 100%;
  height: 100%;

  animation: ${({isLoading}) => (isLoading ? "heartbeat 900ms infinite" : "")};
  animation-play-state: ${({isLoading}) => (isLoading ? "running" : "paused")};
`

export default Loader
