// 3rd party
import React from "react"
import {useSelector} from "react-redux"
import styled from "styled-components"
import {TransitionGroup, CSSTransition} from "react-transition-group"

// components
import ControllerItem from "./controllerItem"
import {device, transitions, controllerSizes} from "../../constants/css"
import {correctValue} from "../../utils/helpers"

let {sizes, totals, translates, ratios, exitTranslates, exitRatio} = controllerSizes
let timeout = transitions.controllerRow

export function ControllerRow({row, rowI, placeholder, isControllerCollapsed, activeTrack}) {
  // console.log("row rerender")
  const playlists = useSelector(state => state.playlists)
  //let playlists = [{node: {tracks: [null, null, null, null, null]}}]
  let playlistIndex = correctValue(rowI, playlists.length)
  let playlist = playlists[playlistIndex]?.node

  let zIndex = !placeholder ? 6 - Math.abs(row) : ""
  let translate = !isControllerCollapsed || placeholder ? translates[-row + 2] : 0
  let exitTranslate = exitTranslates[-row + 2]
  let ratio = ratios[-row + 2]

  let tracks = playlist?.content?.tracks?.length && !placeholder ? playlist.content.tracks : [null, null, null, null, null]
  let indices = []
  /*for (var i = activeTrack - 2; i <= activeTrack + 2; i++) {
    indices.push(i)
  }*/

  let numberAround = 2
  for (var i = activeTrack - numberAround; i <= activeTrack + numberAround; i++) {
    indices.push(i)
  }
  if (placeholder) {
    indices = [-2, -1, 0, 1, 2]
  }

  return (
    <LiRow id={"row-" + rowI} {...{translate, exitTranslate, ratio, totals, zIndex}}>
      <List>
        <TransitionGroup>
          {indices.map(i => {
            var index = i
            index = correctValue(index, tracks.length)
            let level = placeholder ? -i : activeTrack - i
            let track = tracks[index]
            let className = ""
            if (level > 0) {
              className = "left"
            }
            if (level < 0) {
              className = "right"
            }
            return (
              <CSSTransition {...{timeout}} key={i} classNames={isControllerCollapsed ? "" : className}>
                <ControllerItem
                  {...{level, row, isControllerCollapsed, placeholder}}
                  trackIndex={index}
                  albumCover={track?.featuredImage?.node}
                />
              </CSSTransition>
            )
          })}
        </TransitionGroup>
      </List>
    </LiRow>
  )
}

let recenter = "translateY(-50%) translateX(-50%)"

const Li = styled.li`
  will-change: top, transform;
  transform-origin: 50% 50%;
  position: absolute;
  height: ${sizes.small}px;
  width: 100%;
  @media ${device.tabletM} {
    height: ${sizes.medium}px;
  }
  @media ${device.tabletL} {
    height: ${sizes.large}px;
  }

  align-self: center;
  justify-self: center;
`

const LiRow = styled(Li)`
  left: 50%;
  top: 50%;
  transform: ${({ratio, translate}) => `${recenter} translateY(${translate}%) scale(${ratio})`};
  transition: all ${({background}) => (background ? transitions.ornaments : transitions.controllerRow)}ms ease-out;
  z-index: ${({zIndex}) => zIndex};

  // transition styles
  &.right-enter,
  &.left-enter,
  &.right-exit,
  &.left-exit {
    transition: all 0s;
  }
  &.right-enter {
    opacity: 0;
    transform: ${({ratio, translate, exitTranslate}) =>
      `${recenter} translateY(${translate + exitTranslate}%) scale(${ratio * exitRatio})`};
  }

  &.left-enter {
    opacity: 0;
    transform: ${({ratio, translate, exitTranslate}) =>
      `${recenter} translateY(${translate - exitTranslate}%) scale(${ratio * exitRatio})`};
  }

  &.right-exit {
    opacity: 1;
    transform: ${({ratio, translate}) => `${recenter} translateY(${translate}%) scale(${ratio})`};
  }

  &.left-exit {
    opacity: 1;
    transform: ${({ratio, translate}) => `${recenter} translateY(${translate}%) scale(${ratio})`};
  }

  &.right-enter-active,
  &.left-enter-active,
  &.right-exit-active,
  &.left-exit-active,
  &.right-enter-done,
  &.left-enter-done {
    transition: all ${transitions.controllerRow}ms ease-out;
  }
  &.right-enter-active {
    opacity: 1;
    transform: ${({ratio, translate}) => `${recenter} translateY(${translate}%) scale(${ratio})`};
  }
  &.left-enter-active {
    opacity: 1;
    transition: all ${transitions.controllerRow}ms ease-out;
    transform: ${({ratio, translate}) => `${recenter} translateY(${translate}%) scale(${ratio})`};
  }

  &.right-exit-active {
    opacity: 0;
    transform: ${({ratio, translate, exitTranslate}) =>
      `${recenter} translateY(${translate + exitTranslate}%) scale(${ratio * exitRatio})`};
  }

  &.left-exit-active {
    opacity: 0;
    transform: ${({ratio, translate, exitTranslate}) =>
      `${recenter} translateY(${translate - exitTranslate}%) scale(${ratio * exitRatio})`};
  }
`

const List = styled.ul`
  list-style: none;
  position: relative;
`

export default React.memo(ControllerRow)
